<template>
	<div class="bg-primary flex flex-wrap md:justify-center lg:justify-start">
		<div class="short-about-inner sm:px-0 px-2 sm:py-0 py-6 max-w-7xl w-full flex items-center justify-start m-auto">
			<div
			class="
				w-full
				xl:w-1/5
				
				py-4
				text-gray-100
				flex
				items-center
				h-20
				text-lg
				bg-radical
				triangleBg
				max-w-7xl
				
			"
		>
			Whats so great <br class="hidden xl:block" />
			about ReturnTripBD

			<div class="triangle"></div>
		</div>
		<div class="flex flex-wrap lg:ml-32 md:justify-between lg:justify-center">
			<TextWithIcon
				v-for="(fact, i) in facts"
				:key="i"
				class="ml-12 sm:mr-12 mr-0"
				:text="fact.title"
				:textStyle="textStyle"
				:subText="fact.reason"
				:subTextStyle="subTextStyle"
				:icon="fact.icon"
				:iconStyle="iconStyle"
			/>
		</div>
		</div>
	</div>
</template>

<script>
import { facts } from "@/data/about";
import TextWithIcon from "@/components/atom/TextWithIcon";
export default {
	name: "ShortAbout",
	components: {
		TextWithIcon,
	},
	data() {
		return {
			facts,
			textStyle: "text-gray-100 uppercase",
			subTextStyle: "text-gray-100 text-xs uppercase",
			iconStyle: "sm:text-5xl text-3xl mr-2 text-gray-100",
		};
	},
};
</script>
<style scoped>
.triangle {
	position: relative;
	background: #f63b5d;
}
</style>
