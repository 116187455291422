<template>
	<div class="max-width mx-auto mt-10 rounded-lg bg-primaryDeep">
		<div class="flex flex-col-reverse md:flex-row space-x-2 items-center sm:flex max-w-4xl mx-auto">
			<div class="w-full h-full">
				<img class="" src="/images/mockup-single.png" alt="" />
			</div>
			<div class="w-full text-center md:text-left flex flex-col m-2 my-4 md:justify-center">
				<p class="text-white text-lg md:text-2xl sm:text-left font-bold capitalize">
					Download Return Trip  apps
				</p>
                <p class="text-white text-sm capitalize mx-10 md:mx-0">

				</p>
				<div class="mt-6 md:text-left">
                    <div class="flex justify-center md:justify-start items-center">
                        <a target="_blank" href="https://play.google.com/store/apps/details?id=com.returntripbd&pli=1">
                            <img class="w-44 h-14" src="/images/play-google.png" alt="download app">
                        </a>
                    </div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "AppInfo",
};
</script>

<style>
    .max-width {
        max-width: 1260px;
    }
</style>