import feedProvider from "./feed-provider-card/feed-provider-card";
import feedCustomer from "./feed-customer-card/feed-customer-card";
import completedTrips from "./completed-trips-card/completed-trips-card";
import carInfo from "./car-info-card/car-info-card";

export default {
    props:["currentCard"],
    components: {
        feedProvider,
        feedCustomer,
        completedTrips,
        carInfo,
      }
}
