<template>
  <component 
    :trip="trip"
    :index="index"
    :showBookNow="showBookNow"
    :currentIndex="currentIndex"
    :bookingLoader="bookingLoader"
    :is="currentCard" 
    @onClick="()=>$emit('showBooking')"
  >
    <template v-slot:notification>
      <slot name="notification"></slot>
    </template>
    <template v-slot:editContent>
      <slot name="editContent"></slot>
    </template>
    <template v-slot:image>
      <slot name="image"></slot>
    </template>
  </component>
</template>

<script>
import cardFunctions from "@/components/molecule/card/card.functions";

export default {
  name: "Card",
  extends: cardFunctions,
  emits: ['showBooking'],

  props: {
    trip: {
      type: Object,
      default: "",
    },
    index: {
      type: Number,
    },
    currentIndex: {
      type: Number
    },
    currentCard: {
      type: String
    },
    showBookNow: {
      type: Boolean,
      default: true
    },
    bookingLoader: {
      type: Boolean,
    }
  },
};
</script>
<style scoped>
@import "./card.styles.css";
</style>
