<template>
	<div class="flex items-center switch-lang" :class="[$i18n.locale == 'bn' ? 'bn':'']">
		<select v-model="$i18n.locale" class=" language-select bg-transparent text-gray-700 text-sm">
			<option
				class="text-gray-600"
				v-for="locale in $i18n.availableLocales"
				:key="`locale-${locale}`"
				:value="locale"
			>
				{{ locale == "en" ? "Eng" : "বাংলা" }}
			</option>
		</select>
	</div>
</template>

<script>
export default {
	name: "SwitchLang",
	data() {
		return {};
	},
	mounted() {
		var userdata = localStorage.getItem("initdata");
		userdata = JSON.parse(userdata);
		if (userdata) {
			this.$i18n.locale = userdata.local;
		}
		this.$store.state.language = this.$i18n.locale;
	},
	watch: {
		"$i18n.locale": function () {
			var existing = localStorage.getItem("initdata");
			existing = existing ? JSON.parse(existing) : {};
			existing.local = this.$i18n.locale;
			localStorage.setItem("initdata", JSON.stringify(existing));
			this.$store.state.language = this.$i18n.locale;
		},
	},
};
</script>

<style>
  
 .switch-lang {
	height: 30px;
	width: fit-content;
	min-width: 65px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 5px;
	border-radius: 10px;
    border: 1px solid #D71920;
	color: #D71920;
 }
 .language-select {
	 outline: none;
 }

 .switch-lang select , .switch-lang option {
	 width: 100%;
 }
 .switch-lang select  {
	color: #D71920;
 }
</style>
