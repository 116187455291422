<template>
	<div>
		<!-- <Status v-if="tripStatus == 'completed'" :status="tripStatus" /> -->
		<button
			@click="onPublishTrip"
			v-if="tripStatus == 'draft'"
			class="text-xs rounded-full p-2 border border-green-100 bg-green-300 hover:bg-green-400 text-white transition duration-500"
		>
			Publish
		</button>

		<template v-if="userType == 'provider'">
			<!-- <button
				@click="onStarted"
				v-if="confirmBookingStatus && tripStatus == 'hold'"
				class="text-xs rounded-full p-2 border border-green-100 bg-green-300 hover:bg-green-400 text-white transition duration-500"
			>
				Start trip
			</button> -->

			<button
				@click="completeTrip"
				v-if="tripStatus == 'hold'"
				class="text-xs rounded-full p-2 border border-blue-100 bg-blue-300 hover:bg-blue-400 text-white transition duration-500"
			>
				Complete trip
			</button>
		</template>
		<ConfirmationDialog :show="showDialog">
			<template v-slot>
				<ConfirmationComponent
					@denied="onDenied"
					@accepted="onAccepted"
					:title="confirmationTitle"
					:status="type"
					:text="text"
				/>
			</template>
		</ConfirmationDialog>
	</div>
</template>

<script>
import ConfirmationDialog from "@/components/atom/ConfirmationDialog";
import ConfirmationComponent from "@/components/atom/ConfirmationComponent";
import Status from "@/components/atom/Status";

export default {
	name: "TripStatusChangeComponent",
	data: () => ({
		showDialog: false,
		type: "",
		confirmationTitle: "",
		text: "",
	}),
	components: {
		ConfirmationDialog,
		ConfirmationComponent,
		Status,
	},
	methods: {
		completeTrip() {
			this.confirmationTitle = "ট্রিপ কমপ্লিট করুন";
			this.type = "completed";
			this.text =
				"আপনি আপনার ট্রিপ সম্পূর্ণ করতে চলেছেন, অনুগ্রহ করে গ্রাহকের কাছ থেকে " +
				this.price.toLocaleString("bn-BD") +
				" টাকা সংগ্রহ করুন।";
			this.showDialog = true;
		},
		onPublishTrip() {
			this.confirmationTitle = "Publish your trip";
			this.type = "active";
			this.text =
				"You are about to publish your trip, after that you wont be able to edit your trip anymore.";
			this.showDialog = true;
		},
		// onStarted() {
		// 	this.confirmationTitle = "Start your trip";
		// 	this.type = "started";
		// 	this.text =
		// 		"You are about to start your trip, have nice journey, drive safely.";
		// 	this.showDialog = true;
		// },
		onDenied() {
			this.showDialog = false;
		},
		onAccepted(type) {
			this.$emit("updateTripStatus", type);

			this.showDialog = false;

			if (type == "completed") {
				// this.$store.commit("toogleModal");
				// this.$store.commit("setWalletData", {
				// 	trip: this.trip,
				// 	service_charge: 0.05,
				// });
			}
		},
	},
	props: {
		tripStatus: {
			default: "",
		},
		price: {
			default: "",
		},
		userType: {
			default: "",
		},
		confirmBookingStatus: {
			default: false,
		},
		trip: {
			type: Object,
		},
	},
};
</script>
