<template>
  <component :is="layout">
    <router-view v-slot="{ Component }">
      <transition name="fade">
        <component :is="Component" />
      </transition>
    </router-view>
  </component>
</template>

<script>
  import { useI18n } from 'vue-i18n';
  import { provide } from 'vue'
  const default_layout = "master";
  import { useToast } from "vue-toastification";

  export default {
    name: 'App',
    computed: {
    layout() {
        return (this.$route.meta.layout || default_layout) + "-layout";
      },
    },
    setup() {
      const { t } = useI18n;
      const toast = useToast();
      const showError = (message, position='bottom-center') => {
        toast.error(message, {position: position})
      };
      const showSuccess = (message, timeout=5000) => {
        toast.success(message, {timeout: timeout})
      };
      const showToastNotification = (message, timeout=10000) => {
        toast.success(message, {timeout: timeout})
      };
      provide('showError', showError);
      provide('showSuccess', showSuccess);
      provide('showToastNotification', showToastNotification);
      return {
        t
      }
    }
  }
</script>

<style>
  @import url('./assets/style/transition.css');
  @import url('./assets/style/app.css');
  @import url('./assets/style/style.css');
  @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Bengali&display=swap');
</style>
