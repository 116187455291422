<template>
    <div class="rtb-landing-page max-w-md mx-auto rtb-lading-page">
        <div class="swiper-container splash-rtb max-w-md mx-auto rounded-lg">
            <div class="swiper-wrapper">
                <div class="max-width-360">
                    <swiper
                        :loop="true"
                        :autoplay="{delay: 5000}"
                        :slides-per-view='1'
                        :space-between="10"
                        :pagination="{
                            clickable: true
                        }"
                        :modules="modules"
                    >
                        <swiper-slide
                            v-for="(item, index) in images"
                            class="max-w-full overflow-hidden rounded-sm"
                        >
                            <img :src="item.src" alt="Slide Image" >
                        </swiper-slide>
                    </swiper>
                </div>
            </div>
        </div>
<!--        <div
            class="flex items-center justify-between h-16 mx-2 bg-white my-5 rounded-lg py-6 px-2 cursor-pointer"
            @click="goToEstimatedFare"
        >
            <div class="flex items-center">
                <div class="cost-image">
                    <img class="h-8 w-8 object-contain" src="images/cost.png" alt="estimated fare">
                </div>
                <p class="sm:text-sm text-xs font-bold text-black ml-2 est-title">আপনার যাত্রার সম্ভাব্য খরচ দেখুন</p>
            </div>

            <div class="flex items-center bg-red-600 sm:py-2 py-1 sm:px-3 px-2 rounded-lg text-white sm:text-sm w-fit">
                <p class="pr-1 text-xs est-btn-text">সম্ভাব্য খরচ</p>
                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="14" viewBox="0 0 10 14" fill="none">
                    <path
                        d="M1 2.71429C1 2.25963 1.16857 1.82359 1.46863 1.5021C1.76869 1.18061 2.17565 1 2.6 1C3.02435 1 3.43131 1.18061 3.73137 1.5021C4.03143 1.82359 4.2 2.25963 4.2 2.71429V10.4286C4.2 11.1106 4.45286 11.7646 4.90294 12.2468C5.35303 12.7291 5.96348 13 6.6 13C7.23652 13 7.84697 12.7291 8.29706 12.2468C8.74714 11.7646 9 11.1106 9 10.4286V10M1.8 6.14286H6.6"
                        stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
        </div>-->
        <div class="running-trips h-fit grid gap-6 bg-white p-2 rounded-lg">
            <div class="single-trip" v-for="trip in tripList" :key="trip.id">
                <div class="trip-heading flex items-center px-3 justify-between text-base">
                    <div class="time-remaining flex items-center gap-2">
                  <span class="relative flex h-3 w-3 -mt-1">
                    <span
                        class="animate-ping absolute inline-flex h-full w-full rounded-full opacity-75"
                        :class="trip.status === 'active' ? 'bg-green-400' : 'bg-red-400'"
                    >
                    </span>
                    <span
                        class="relative inline-flex rounded-full h-3 w-3"
                        :class="trip.status === 'active' ? 'bg-green-500' : 'bg-red-500'"
                    ></span>
                  </span>
                        <Countdown :trip="trip"/>
                    </div>
                    <div class="trip-state">
                        <p v-if="trip.status === 'active'" class="text-xs">বিডিং চলছে</p>
                        <p v-if="trip.status ==='extended'" class="text-xs text-red-500">বিড সম্পন্ন হয়েছে। পছন্দমত বিড
                            কনফার্ম করুন</p>
                    </div>
                    <div class="trip-details flex items-center gap-2 cursor-pointer" @click="navigateToTrip(trip.id)">
                        <p>বিস্তারিত</p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="9" height="14" viewBox="0 0 9 14" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M8.54385 5.95285C8.83594 6.22828 9 6.60164 9 6.99092C9 7.3802 8.83594 7.75356 8.54385 8.02899L2.6626 13.57C2.37 13.8454 1.9732 14.0001 1.5595 14C1.1458 13.9999 0.749079 13.845 0.456615 13.5695C0.164152 13.2939 -9.74591e-05 12.9202 4.3385e-08 12.5306C9.75458e-05 12.1409 0.164534 11.7673 0.457135 11.4919L5.23617 6.99092L0.457135 2.48999C0.172875 2.21306 0.0154739 1.84207 0.0188335 1.45691C0.0221932 1.07174 0.186044 0.703231 0.475097 0.430742C0.76415 0.158253 1.15528 0.00358899 1.56423 6.1677e-05C1.97319 -0.00346563 2.36726 0.144426 2.66156 0.411883L8.54489 5.95187L8.54385 5.95285Z"
                                  fill="#4CBC48"/>
                        </svg>
                    </div>
                </div>
                <div class="trip-route px-3 py-5 flex items-center sm:gap-5 gap-3">
                    <div class="route-icon flex items-center gap-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none">
                            <g clip-path="url(#clip0_671_882)">
                                <path
                                    d="M17.9123 12.3868L13.5439 2.54307C13.3886 2.193 13.0501 1.96875 12.6776 1.96875H9.62795L9.70452 2.68119C9.72014 2.82639 9.6042 2.95312 9.45577 2.95312H8.54452C8.39608 2.95312 8.28014 2.82639 8.29577 2.68119L8.37233 1.96875H5.32264C4.94983 1.96875 4.61139 2.193 4.45608 2.54307L0.0876423 12.3868C-0.20142 13.0387 0.25983 13.7812 0.954517 13.7812H7.10577L7.42795 10.7764C7.45483 10.526 7.6692 10.3359 7.92514 10.3359H10.0751C10.3311 10.3359 10.5455 10.526 10.5723 10.7764L10.8945 13.7812H17.0458C17.7405 13.7812 18.2017 13.0387 17.9123 12.3868ZM8.13764 4.15775C8.14413 4.09727 8.17314 4.0413 8.21907 4.00063C8.265 3.95996 8.32461 3.93747 8.38639 3.9375H9.6142C9.74202 3.9375 9.84952 4.03255 9.86295 4.15775L10.0067 5.49835C10.0301 5.71645 9.85639 5.90625 9.63389 5.90625H8.36702C8.1442 5.90625 7.97077 5.71645 7.9942 5.49835L8.13764 4.15775ZM9.86389 9.35156H8.13608C7.8392 9.35156 7.60764 9.09839 7.63889 8.8077L7.79733 7.33113C7.8242 7.08073 8.03858 6.89062 8.29452 6.89062H9.70545C9.96139 6.89062 10.1758 7.08073 10.2026 7.33113L10.3611 8.8077C10.3923 9.09839 10.1608 9.35156 9.86389 9.35156Z"
                                    fill="#363636"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_671_882">
                                    <rect width="18" height="15.75" fill="white"/>
                                </clipPath>
                            </defs>
                        </svg>
                        <p>যাত্রা রুট</p>
                    </div>
                    <div class="starting-point">
                        <p>{{ trip.pickup_full_address.slice(0, 15) }}...</p>
                    </div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                            <path
                                d="M5.95438 1.8411C5.74348 2.05207 5.625 2.33817 5.625 2.63648C5.625 2.93479 5.74348 3.22088 5.95438 3.43185L11.5231 9.0006L5.95438 14.5694C5.74946 14.7815 5.63606 15.0657 5.63863 15.3607C5.64119 15.6557 5.7595 15.9378 5.96809 16.1464C6.17667 16.355 6.45884 16.4733 6.75381 16.4759C7.04878 16.4784 7.33296 16.365 7.54513 16.1601L13.9093 9.79598C14.1202 9.58501 14.2386 9.29891 14.2386 9.0006C14.2386 8.70229 14.1202 8.4162 13.9093 8.20523L7.54513 1.8411C7.33417 1.6302 7.04807 1.51172 6.74976 1.51172C6.45145 1.51172 6.16535 1.6302 5.95438 1.8411Z"
                                fill="#363636"/>
                        </svg>
                    </div>
                    <div class="end-point">
                        <p>{{ trip.destination_full_address.slice(0, 15) }}...</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapMutations, mapGetters} from 'vuex';
import TripManager from "@/services/module/trip";
import Countdown from '../feed/Countdown.vue';

//import Swiper from 'swiper';
/*import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';*/

import { Swiper, SwiperSlide } from 'swiper/vue';
import {Autoplay, Pagination} from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';

export default {
    data() {
        return {
            tripManager: new TripManager(),
            tripList: null,
            swiper: null,
            images: [
                {
                    src: "images/feature.jpg",
                },
            ],
        }
    },
    components: {
        Countdown,
        Swiper,
        SwiperSlide,
    },
    setup() {
        return {
            modules: [Pagination, Autoplay],
        };
    },

    methods: {
        ...mapMutations({
            setDialogComponent: "setDialogComponent",
        }),
        navigateToTrip(id) {
            this.$router.push(`/trip-bidding/${id}`)
        },
        goToEstimatedFare() {
            this.$router.push({name: 'EstimatedFare'})
        },
        async fetchTrips() {
            let requestTripsRes = await this.tripManager.trips({
                descOrderColumnName: "created_at",
                status: "active+extended",
            });
            if (requestTripsRes.success) {
                this.tripList = requestTripsRes.data
            }
        },

        setOfferDialog() {
            this.setDialogComponent('OfferModal')
        },
        navigateTo(path) {
            this.$router.push(path);
        },

    },
    mounted() {
        this.fetchTrips();
    },
}
</script>

<style lang="scss" scoped>
.rtb-landing-page {
    height: calc(100vh - 100px);
    font-family: 'Noto Sans Bengali',serif;
    background: #F8FAFC;
}

.rtb-title {
    color: #D72028;
    font-weight: bold;
}

.max-width-360 {
    max-width: 360px;
    margin: 2px auto;
}


.running-trips {
    .single-trip {
        border-radius: 10px;
        background: #FFF;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);

        .trip-heading {
            height: 40px;
            border-radius: 10px;
            background: rgba(76, 188, 72, 0.15);
        }

        .time-remaining, .trip-state, .trip-details {
            p {
                color: #4CBC48;
                font-weight: 600;
            }
        }

        .trip-route {
            font-size: 12px;

            .route-icon {
                p {
                    color: #77818A;
                    font-weight: 700;
                }
            }
        }
    }
  }

  @media (max-width: 350px) {
    .estimated-fare {
      .est-title, .est-btn-text {
      font-size: 11px;
      }
      .cost-image {
        height: 24px;
        width: 24px;
      }
      svg { 
        height: 10px;
        width: 10px;
      }
    }
  }
</style>