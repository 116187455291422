<template>
	<div class="container mx-auto mt-10">
		<TitleSubtitle title="what our client says" subtitle="testimonial" />
	</div>

	<div class="carousel-container w-full bg-gradient-to-r from-red-300 to-red-100 ">
		<Carousel
			class="carousel-items mt-10 max-w-7xl mx-auto top-20"
			:slides="testimonials"
			:responsive="responsive"
		>
			<template v-slot="{ slide }">
				<TestimonialCard :testimonial="slide" />
			</template>
		</Carousel>
	</div>
</template>

<script>
import Carousel from "@/components/atom/Carousel";
import { testimonials } from "@/data/testimonial";
import TestimonialCard from "@/components/molecule/home/TestimonialCard";
import TitleSubtitle from "@/components/atom/TitleSubtitle";
import { responsive } from "@/config/carousel";
export default {
	name: "Testimonial",
	components: {
		TitleSubtitle,
		TestimonialCard,
		Carousel,
	},
	data() {
		return {
			testimonials,
			responsive,
		};
	},
};
</script>

<style>
.carousel-container {
	/* background: #e11d48; */
}

.carousel-items {
   margin-bottom: 200px;
}
</style>
