export const facts = [
	{
		title: 'local support',
		reason: 'with a live person',
		icon: 'mdi mdi-headphones'
	},{
		title: 'verified',
		reason: 'personal driver',
		icon: 'mdi mdi-check-circle-outline'
	},{
		title: 'secure',
		reason: 'payment procedure',
		icon: 'mdi mdi-shield-check-outline'
	}
];

export const about = "Return Trip BD is a different type of Car rental platform that provides car rental service at a cheap price, there are many types of car rentals available such as one-way, round trip, long trip, airport pick and drop, wedding trip, etc... We are on a mission to change old car rental services. and we hope to generate new customers with good quality service.";