<template>
	<div
		class="pb-5 mb-4 instruction rounded-lg hover:bg-primary hover:shadow-xl transition-all duration-500 ease-in-out shadow-lg md:w-1/3 mx-4 md:mx-0 h-80"
        :class="serial % 2 !== 0 ? 'md:mx-5' : ''"
	>
		<div class="flex justify-end">
			<span
                class="text-gray-300"
                :class="largeSerial">
                    0{{ serial + 1 }}
            </span>
		</div>

		<p class="font-semibold px-3 text-gray-600 text-xl mt-10">
			<span class="little-serial text-gray-300 font-bold">
                0{{ serial + 1 }}
            </span>
			{{ instruction.title }}
		</p>

		<div class="pl-10 block pr-3 md:h-24 my-5">
			<p class="text-lg text-gray-500">{{ instruction.detail }}</p>
		</div>
	</div>
</template>

<script>
export default {
	name: "Instruction",
	components: {},
	props: {
		instruction: {
			required: true,
		},
		serial: {
			required: true,
		},
	},
	data() {
		return {
			largeSerial:
				"text-7xl leading-none md:text-8xl font-bold text-gray-150 pr-2",
		};
	},
	computed: {},
	watch: {},
	methods: {},
};
</script>

<style>
.little-serial {
	color: #232323;
}
.instruction:hover p {
	color: #fff;
}

.instruction:hover .little-serial {
	color: #fff;
}
</style>
