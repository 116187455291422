<template>
	<div class="relative">
	<div>
		<div class="mt-10 shadow-card min-h-[180px] bg-white rounded-t-md">
			<div class="flex justify-between items-center rounded-t-md bg-whiteBlue">
				<div class="flex justify-between items-center">
					<div
						class="
							bg-success
							rounded-tl-md
							pl-4
							py-2
							text-xs
							relative
							text-white
							uppercase
							font-sans
							border-r-14 border-success
						"
					>
                        <span class="mdi mdi-calendar mr-1"></span>
						{{ trip.journey_date }}
                        <span class="mdi mdi-clock-time mr-1"></span>
						{{ trip.journey_time }}
						<div
							class="
								absolute
								top-0
								-right-6
								bg-success
								w-5
								h-full
								transform
								-skew-x-30
							"
						></div>
					</div>
					<div class="hidden md:flex items-center pl-8">
                    <!--<div class="mr-3">
							<span class="mdi mdi-calendar-blank text-success mr-1"></span>
							<span class="text-bluishBlack font-medium uppercase text-sm">{{
								trip.journey_date
							}}</span>
						</div>
						<div>
							<span class="mdi mdi-clock-time-five text-success mr-1"></span>
							<span class="text-bluishBlack font-medium uppercase text-sm">
								{{ trip.journey_time }}
							</span>
						</div>-->
					</div>
				</div>
				<div class="md:pr-2 flex justify-end md:justify-between">
                    <div class="mr-1 cursor-pointer">
                        <button
                            class="bg-blue-400 text-gray-100 text-center text-xs px-3 py-1 rounded-sm"
                            @click="showRating = true"
                            :disabled="trip?.given_rating != null"
                            :class="trip?.given_rating != null ? 'cursor-not-allowed' : ''"
                        >
                            <span class="mdi mdi-star text-yellow-200"></span>
                            {{ trip?.given_rating != null ? Math.floor(trip?.given_rating?.rating) + ' রেটিং' :  "রেটিং দিন"  }}
                        </button>
                    </div>
					<div class="notification inline-block md:px-6">
						<slot name="notification"></slot>
					</div>
					<p
						class="
							hidden
							md:inline-block
							text-pri-sm
							sm:text-pri-xs
							md:text-pri-md
						"
						v-if="trip && trip.price"
					>
						<span class="px-2 text-pri-md sm:text-pri-xs">
							৳{{ parseInt(trip.price) }}
						</span>
					</p>
				</div>
			</div>
			<div class="flex md:hidden justify-between items-center gap-5 px-2 mt-4">
				<div class="flex items-center">
                <!--<div class="mr-3">
						<span class="mdi mdi-calendar-blank text-success mr-1"></span>
						<span class="text-bluishBlack font-medium uppercase text-sm">
							{{ trip.journey_date }}
						</span>
					</div>
					<div>
						<span class="mdi mdi-clock-time-five text-success mr-1"></span>
						<span class="text-bluishBlack font-medium uppercase text-sm">
							{{ trip.journey_time }}
						</span>
					</div>-->
				</div>
				<div class="bg-green-50 rounded p-0.5">
					<span class="px-2 text-primary text-lg">
						৳{{ trip.price ? parseInt(trip.price) : "---" }}
					</span>
				</div>
			</div>
			<div class="hidden md:flex relative">
				<div class="grid grid-cols-12 gap-2 md:pl-8 pl-2 pt-6 pb-2 w-full">
					<div class="col-span-8 sm:col-span-4 lg:col-span-2">
						<div class="font-semibold text-gray-400 text-sm">
							{{ $t("triCreateForm.pickupLocation") }}
						</div>
						<div
							class="text-bluishBlack text-sm tracking-widest font-medium my-2"
							v-if="trip && trip.geo_location && trip.geo_location.location"
						>
                            {{ trip?.pickup_full_address }},
							{{
								$store.state.language == "en"
									? trip.geo_location.location.pick_district.coordinate.name.en
									: trip.geo_location.location.pick_district.coordinate.name.bn
							}}
						</div>
					</div>

					<div class="col-span-8 sm:col-span-4 lg:col-span-2">
						<div class="font-semibold text-gray-400 text-sm">
							{{ $t("triCreateForm.destinationLocation") }}
						</div>
						<div
							class="text-bluishBlack text-sm tracking-widest font-medium my-2"
							v-if="trip && trip.geo_location && trip.geo_location.location"
						>
                            {{ trip?.destination_full_address }},
							{{
								$store.state.language == "en"
									? trip.geo_location.location.dest_district.coordinate.name.en
									: trip.geo_location.location.dest_district.coordinate.name.bn
							}}
						</div>
					</div>

					<div class="sm:col-span-8 lg:col-span-6 col-span-8">
						<div
							class="
								grid grid-cols-3
								sm:grid-cols-4
								md:grid-cols-3
								col-span-2
								md:col-span-3
							"
						>
							<div class="col-span-3 sm:col-span-2 md:col-span-1">
								<div class="font-semibold text-gray-400 text-sm">
									{{ $t("triCreateForm.numberOfPeople") }}
								</div>
								<div
									class="
										text-bluishBlack text-sm
										flex
										items-center
										gap-2
										tracking-widest
										font-medium
										my-2
										capitalize
									"
								>
									{{trip.car_type+' '+ getTripCarType(trip.car_type)}}
								</div>
							</div>


							<div class="col-span-3 sm:col-span-2 md:col-span-1">
								<div class="font-semibold text-gray-400 text-sm">
									{{ $t("triCreateForm.type") }}
								</div>
								<div
									class="
										text-bluishBlack text-sm
										tracking-widest
										font-medium
										my-2
									"
								>
									{{ trip?.type.replace("e", "e ") }}
								</div>
							</div>

							<div class="col-span-3 sm:col-span-4 md:col-span-1">
								<div class="font-semibold text-gray-400 text-sm">
									{{ $t("triCreateForm.carModel") }}
								</div>
								<div
									class="
										text-bluishBlack text-sm
										tracking-widest
										font-medium
										my-2
									"
								>
									{{ trip.car?.car_brand }}
									{{ trip.car_model }}
									{{ trip?.car?.manufacture_year }}
									<p class="text-sm text-gray-400">
										{{ trip.car?.car_type }} ({{ trip.no_of_seat }} seat)
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- mobile view  -->
			<div class="md:hidden px-3 space-y-3 mt-3">
				<div>
					<DestinationViewer :trip="trip" />
				</div>

				<div>
					<CarViewer
						:image="trip.car?.images[0]?.file_path"
						cardBg="bg-green-50"
						:registration="trip.car?.registration_number"
						:trip="trip"
						:model="
							trip.car?.car_brand +
							' ' +
							trip.car_model +
							' ' +
							trip?.car?.manufacture_year
						"
					/>
				</div>
				<div class="pb-3"></div>
			</div>
			<!--end of mobile view  -->
		</div>
		<div class="border-b-3 border-success"></div>
	</div>
    <div v-if="trip?.status == 'completed'" class="pb-3">
        <div class="fixed z-10 inset-0 overflow-y-auto" v-show="showRating">
            <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div class="fixed inset-0 transition-opacity">
                    <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>
                <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                    <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                            <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">

                            </h3>
                            <div class="mt-2">
                                <div class="flex">
                                    <div class="flex-grow">
                                        <h2 class="text-gray-700 capitalize text-lg font-semibold w-full">
                                            রেটিং এবং রিভিও দিন ({{ stars }} / {{ maxStars }})
                                        </h2>
                                    </div>
                                    <i
                                        @click="showRating = false"
                                        class="mdi mdi-close cursor-pointer hover:text-primary text-gray-400 w-6 h-6 p-1 rounded-full bg-red-50 leading-none"
                                    ></i>
                                </div>
                                <div class="rating">
                                    <ul class="list">
                                        <li @click="rate(star)" v-for="star in maxStars" :class="{ 'active': star <= stars }" :key="star.stars" class="star">
                                            <i :class="star <= stars ? 'mdi mdi-star' : 'mdi mdi-star-outline'"></i>
                                        </li>
                                    </ul>
                                </div>
                                <div class="flex flex-col items-center">
                                    <textarea
                                        v-model="review"
                                        class="w-full h-20 p-2 border border-gray-200 rounded"
                                        placeholder="ট্রিপের অভিজ্ঞতা কেমন ছিল?"
                                    >
                                    </textarea>
                                    <button @click="ratingSubmit" class="bg-blue-500 hover:bg-blue-700 text-white font-bold p-2 mt-4 rounded">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
	<button v-if="trip.status != 'active' && trip.status != 'hold' && trip.status != 'started'" @click="setDialogComponent({name: 'TripCreateForm', trip: trip})" class="bg-white hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded w-64 justify-center mx-auto flex absolute left-0 right-0 text-center -bottom-2">
			পুনরায়  রিকোয়েস্ট করুন
	</button>
   </div>
</template>

<script>
import DestinationViewer from "@/components/atom/DestinationViewer";
import SeatAndTripTypeViewer from "@/components/atom/SeatAndTripTypeViewer";
import CarViewer from "@/components/atom/CarViewer";
import {mapMutations} from "vuex";
import Form from "@/services/form";

export default {
	name: "HistoryTripCard",
	components: {
		DestinationViewer,
		SeatAndTripTypeViewer,
		CarViewer,
	},
	props: {
		trip: {
			type: Object,
			default: "",
		},
	},
    data() {
        return {
            form: new Form(),
            grade: 0,
            maxStars: 5,
            stars: 0,
            showRating: false,
            review: '',
        };
    },

	methods: {
        ...mapMutations({
            setDialogComponent: "setDialogComponent",
        }),
		getTripCarType(type){
			if(type == 'mini micro'){
				return '(7 seats)';
			}
			if(type == 'sedan car'){
				return '(4 seats)'
			}
            if (type == 'premium car') {
                return '(4 seats)';
            }
			if(type == 'micro'){
				return '(10 seats)';
			}
		},
        rate(star) {
            if (typeof star === 'number' && star <= this.maxStars && star >= 0) {
                this.stars = this.stars === star ? star - 1 : star
            }
        },
        ratingSubmit() {
            console.log(this.trip.id)
            this.form.post(`/trip/rate-trip/${this.trip.id}`,
                {
                    rating: this.stars,
                    review: this.review
                })
                .then((response) => {
                    this.showRating = false
                    window.location.reload()
                })
                .catch((error) => {
                    console.log(error)
                })
        }
	},
};
</script>

<style scoped>
.shadow-card {
	min-height: 180px;
}
.add-rating svg {
	stroke: #e11d48;
}

 .rating {
     display: flex;
     flex-direction: column;
     align-items: center;
     padding: 15px;
     color: #b7b7b7;
     background: #fff;
 }
.rating .list {
    padding: 0;
    margin: 0 20px 0 0;
}
.rating .list:hover .star {
    color: #ffe100;
}
.rating .list .star {
    display: inline-block;
    font-size: 42px;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}
.rating .list .star:hover ~ .star:not(.active) {
    color: inherit;
}
.rating .list .star:first-child {
    margin-left: 0;
}
.rating .list .star.active {
    color: #ffe100;
}
</style>
