<template>
  <div class="custom-time">
    <swiper
        :class="[deviceType === 'mobile'? 'mobile': 'other']"
        :direction="'vertical'"
        @swiper="onSwiper"
        @slideChange="onSlideChange"
        :style="{maxWidth: 380}"
        :navigation="true" 
        :modules="modules" 
        :effect="'coverflow'"
        :grabCursor="true"
        :centeredSlides="true"
        :slidesPerView="6"
        :initialSlide=3
        :coverflowEffect="{
        rotate: 0,
        stretch: 80,
        depth: 160,
        modifier: 1,
        slideShadows: false,
        }"
    >
      <swiper-slide v-for="(time, index) in timeLists" :key="index" :id="time.value">{{ time.time }}</swiper-slide>

    </swiper>
  </div> 
</template>
  <script>
    
    import { Swiper, SwiperSlide } from 'swiper/vue';
    import "swiper/css";
    import "swiper/css/navigation";
    import 'swiper/css/effect-coverflow';
    import { defineComponent } from "vue";
    import { Navigation, EffectCoverflow } from "swiper/modules";
  
    export default defineComponent({
    props: {
        timeLists: {
            type: Object
        },
        changeTime: {
            type: String
        },
        date: {
          type: String
        },
        deviceType: {
          type: String
        }
    },
    name: 'CustomTime',
      components: {
        Swiper,
        SwiperSlide,
      },
    setup(props, {emit}) {
    const onSwiper = async (swiper) => {
        const activeSlide = await swiper.slides[swiper.activeIndex];
        const id = activeSlide.id;
        emit('change-time', id)
      };
      const onSlideChange = async (swiper) => {
        const activeSlide = await swiper.slides[swiper.activeIndex];
        const id = activeSlide.id;
        emit('change-time', id)
      };
      return {
        onSwiper,
        onSlideChange,
        modules: [Navigation, EffectCoverflow],
      };
    },
    });
  </script>

  <style lang="scss">
  .custom-time {
    font-family: 'Noto Sans Bengali', sans-serif;
    height: 160px;
  .swiper {
    width: 100%;
    max-width: 430px;
    height: 100%;
    max-height: 160px;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .swiper-slide-active {
     font-size: 30px;
     font-weight: bold;
  }
  }
  </style>