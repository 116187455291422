export const instructions = [
	{
		title: "Submit a Trip request",
		detail: "Fill up the Trip request form And submit.",
	},
	{
		title: "Choose a Bid",
		detail: "Providers will bid on your Trip request.  If you like the bid, select it and confirm.",
	},

	{
		title: "Contact provider",
		detail: "After confirming the bid, you will get the provider's information.  Then contact the provider.",
	},
];
