<template>
	<div class="flex items-center">
		<span :class="[icon, iconStyle]"></span>
		<div>
			<p :class="textStyle">{{ text }}</p>
			<p :class="subTextStyle">{{ subText }}</p>	
		</div>
	</div>
</template>

<script>
	export default {
		name: 'TextWithIcon',
		props: {
			text: String,
			icon: String,
			textStyle: String,
			iconStyle: String,
			subText: String,
			subTextStyle: String,
		}
	}
</script>