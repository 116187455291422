<template>
	<div :class="cardBg" class="flex justify-between p-2 items-center rounded" v-if="trip">
		<div
			class="car-data flex gap-x-1 items-center flex-wrap"
			v-if="trip"
		>
            <div v-if="trip?.car && trip?.created_by != 'customer'" class="flex flex-col">
                <div class="flex gap-x-1 items-center flex-wrap">
                    <p class="text-gray-500 text-sm">{{ getBrand() }}</p>
                    <p class="text-gray-500 text-sm">{{ trip.car.model }}</p>
                    <p class="text-gray-500 text-sm">{{ trip.car.manufacture_year }}</p>
                </div>
                <div class="flex gap-x-1 items-center flex-wrap">
                    <p class="text-gray-500 text-sm ">{{ trip.car.number_of_seat }} seats</p>
                    <span class="mx-1 text-gray-500 text-sm">|</span>
                    <p class="text-gray-500 text-sm">{{ trip.car.is_air_conditioned ? "AC" : "Non AC" }}</p>
                </div>
            </div>

			<div class="passengers-number" v-if="!trip?.car || trip?.created_by == 'customer'">
			  <div class="capitalize"><span class="mdi mdi-car"></span> {{trip.car_type + ' ' + getTripCarType(trip.car_type) }}</div>
			</div>
		</div>

		<p
			class="text-gray-500 text-sm capitalize flex items-center gap-2"
			v-if="trip && trip?.created_by == 'customer' "
		>
			<i class="mdi mdi-road-variant text-primary text-2xl"></i> {{ trip?.type }}
		</p>
	</div>
</template>

<script>
export default {
	name: "CarViewer",
	data(){
		return {
			usertype : ''
		}
	},
	props: {
		cardBg: {
			default: "bg-blue-50 p-2",
		},

		trip: {
			type: Object,
		},
	},
    methods: {
		getBrand(){
			if(this.trip.car.car_brand){
				return this.trip.car.car_brand;
			}
			if(this.trip.car.brand.name && !this.trip.car.car_brand){
				return this.trip.car.brand.name
			}
		},

		getTripCarType(type){
			if(type == 'mini micro'){
				return '(7 seats)';
			}
			if(type == 'sedan car'){
				return '(4 seats)';
			}
            if (type == 'premium car') {
                return '(4 seats)';
            }
			if(type == 'micro'){
				return '(10 seats)';
			}
		}
	},
	mounted(){
		var userdata = localStorage.getItem('trip_auth_user')
		if(userdata){
			userdata = JSON.parse(userdata)
		    this.usertype = userdata?.type
		}
	}
};
</script>
