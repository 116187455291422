<template>
    <div class="md:py-1 rtb-header">
        <div class="business-logo invisible w-0 h-0">
            <img src="/images/aside-square-black.png" alt="logo"/>
        </div>
        <!-- Off-canvas menu for mobile -->
        <div v-if="user && sidebarOpen" class="md:hidden">
            <div
                @click="sidebarOpen = false"
                v-show="sidebarOpen"
                class="fixed inset-0 z-30 transition-opacity ease-linear duration-500"
            >
            </div>
            <div class="fixed inset-0 flex z-40">
                <div
                    v-if="user && sidebarOpen"
                    class="relative flex flex-col max-w-xs w-full bg-white min-h-screen h-fit transform ease-in-out duration-500"
                    v-click-away="onClickAway"
                >
                    <div class="grid gap-10 p-6 overflow-y-auto mb-32">
                        <div class="close-and-lang flex justify-between items-center">
                            <div class="close cursor-pointer" @click="toggleSidebar(false)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                                     fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                          d="M7.88242 10.0034L13.1854 15.3074C13.4668 15.5888 13.8485 15.7469 14.2464 15.7469C14.6444 15.7469 15.026 15.5888 15.3074 15.3074C15.5888 15.026 15.7469 14.6444 15.7469 14.2464C15.7469 13.8485 15.5888 13.4668 15.3074 13.1854L10.0024 7.88243L15.3064 2.57943C15.4457 2.4401 15.5561 2.2747 15.6315 2.09268C15.7068 1.91066 15.7456 1.71558 15.7455 1.51858C15.7455 1.32158 15.7066 1.12652 15.6312 0.944534C15.5558 0.762548 15.4452 0.597201 15.3059 0.457934C15.1666 0.318668 15.0012 0.208208 14.8192 0.132863C14.6371 0.057517 14.4421 0.0187609 14.2451 0.0188074C14.0481 0.0188538 13.853 0.0577016 13.671 0.133133C13.489 0.208564 13.3237 0.319102 13.1844 0.458435L7.88242 5.76143L2.57942 0.458435C2.44111 0.315105 2.27565 0.200756 2.09268 0.122057C1.90971 0.0433583 1.7129 0.00188689 1.51374 6.29038e-05C1.31457 -0.00176108 1.11703 0.0360986 0.932653 0.111433C0.748274 0.186767 0.580745 0.298068 0.43984 0.43884C0.298935 0.579612 0.187477 0.747037 0.111969 0.931345C0.0364604 1.11565 -0.00158556 1.31315 5.06168e-05 1.51232C0.00168679 1.71149 0.0429722 1.90834 0.121498 2.09138C0.200024 2.27443 0.314218 2.44 0.457417 2.57843L5.76242 7.88243L0.458417 13.1854C0.177022 13.4668 0.0189362 13.8485 0.0189362 14.2464C0.0189362 14.6444 0.177022 15.026 0.458417 15.3074C0.739812 15.5888 1.12146 15.7469 1.51942 15.7469C1.91737 15.7469 2.29902 15.5888 2.58042 15.3074L7.88242 10.0024V10.0034Z"
                                          fill="#D71920"/>
                                </svg>
                            </div>
                            <div class="mid-line"></div>
                            <div class="language-switch">
                                <SwitchLang/>
                            </div>
                        </div>

                        <div class="user-profile cursor-pointer" @click="$router.push('/customer/profile')">
                            <div class="user-icon-name flex items-center gap-3">
                                <div class="image border-0 w-14 border-r-2 border-gray-300">
                                    <img class="w-10 h-10 rounded-full flex items-start justify-center"
                                         src="/images/user-icon.png" alt="user">
                                </div>
                                <div class="user-name-email grid">
                                    <div class="name font-bold text-base">
                                        <p>{{ user.name }}</p>
                                    </div>
                                    <div class="email">
                                        <p>{{ user.email ?? 'example@test.com' }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="menu-item my-profile flex gap-5 items-center ml-14 cursor-pointer"
                             @click="$router.push('/customer/profile')">
                            <div class="icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                     fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                          d="M8 7C8 5.93913 8.42143 4.92172 9.17157 4.17157C9.92172 3.42143 10.9391 3 12 3C13.0609 3 14.0783 3.42143 14.8284 4.17157C15.5786 4.92172 16 5.93913 16 7C16 8.06087 15.5786 9.07828 14.8284 9.82843C14.0783 10.5786 13.0609 11 12 11C10.9391 11 9.92172 10.5786 9.17157 9.82843C8.42143 9.07828 8 8.06087 8 7ZM8 13C6.67392 13 5.40215 13.5268 4.46447 14.4645C3.52678 15.4021 3 16.6739 3 18C3 18.7956 3.31607 19.5587 3.87868 20.1213C4.44129 20.6839 5.20435 21 6 21H18C18.7956 21 19.5587 20.6839 20.1213 20.1213C20.6839 19.5587 21 18.7956 21 18C21 16.6739 20.4732 15.4021 19.5355 14.4645C18.5979 13.5268 17.3261 13 16 13H8Z"
                                          fill="#D71920"/>
                                </svg>
                            </div>
                            <div class="title text-sm font-semibold">
                                <p>আমার প্রোফাইল</p>
                            </div>
                        </div>

                        <div class="menu-item contact flex gap-5 items-center ml-14 cursor-pointer"
                             @click="$router.push('/contact')">
                            <div class="icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                     fill="none">
                                    <path
                                        d="M21 8V7L18 9L15 7V8L18 10L21 8ZM22 3H2C0.9 3 0 3.9 0 5V19C0 20.1 0.9 21 2 21H22C23.1 21 23.99 20.1 23.99 19L24 5C24 3.9 23.1 3 22 3ZM8 6C9.66 6 11 7.34 11 9C11 10.66 9.66 12 8 12C6.34 12 5 10.66 5 9C5 7.34 6.34 6 8 6ZM14 18H2V17C2 15 6 13.9 8 13.9C10 13.9 14 15 14 17V18ZM22 12H14V6H22V12Z"
                                        fill="#D71920"/>
                                </svg>
                            </div>
                            <div class="title text-sm font-semibold">
                                <p>যোগাযোগ</p>
                            </div>
                        </div>
                        <div class="menu-item contact flex gap-5 items-center ml-14 cursor-pointer"
                             @click="$router.push('/about')">
                            <div class="icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                     fill="none" stroke="#D71920" stroke-width="2" stroke-linecap="round"
                                     stroke-linejoin="round" class="feather feather-info">
                                    <circle cx="12" cy="12" r="10"></circle>
                                    <line x1="12" y1="16" x2="12" y2="12"></line>
                                    <line x1="12" y1="8" x2="12.01" y2="8"></line>
                                </svg>
                            </div>
                            <div class="title text-sm font-semibold">
                                <p>আমাদের সম্পর্কে </p>
                            </div>
                        </div>
                        <div class="menu-item help-line flex gap-5 items-center ml-14 cursor-pointer"
                             @click="$router.push('/help')">
                            <div class="icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                     fill="none">
                                    <path
                                        d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13 19H11V17H13V19ZM15.07 11.25L14.17 12.17C13.45 12.9 13 13.5 13 15H11V14.5C11 13.4 11.45 12.4 12.17 11.67L13.41 10.41C13.78 10.05 14 9.55 14 9C14 7.9 13.1 7 12 7C10.9 7 10 7.9 10 9H8C8 6.79 9.79 5 12 5C14.21 5 16 6.79 16 9C16 9.88 15.64 10.68 15.07 11.25Z"
                                        fill="#D71920"/>
                                </svg>
                            </div>
                            <div class="title text-sm font-semibold">
                                <p>হেল্প লাইন</p>
                            </div>
                        </div>
                        <div class="menu-item help-line flex gap-5 items-center ml-14">
                            <div class="icon">
                                <a href="https://wa.me/1871955556">
                                    <svg fill="#D71920" height="24px" width="24px" version="1.1" id="Layer_1"
                                         xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                         viewBox="0 0 324 324" xml:space="preserve">
                                          <g id="XMLID_468_">
                                            <path id="XMLID_469_" d="M227.904,176.981c-0.6-0.288-23.054-11.345-27.044-12.781c-1.629-0.585-3.374-1.156-5.23-1.156
                                              c-3.032,0-5.579,1.511-7.563,4.479c-2.243,3.334-9.033,11.271-11.131,13.642c-0.274,0.313-0.648,0.687-0.872,0.687
                                              c-0.201,0-3.676-1.431-4.728-1.888c-24.087-10.463-42.37-35.624-44.877-39.867c-0.358-0.61-0.373-0.887-0.376-0.887
                                              c0.088-0.323,0.898-1.135,1.316-1.554c1.223-1.21,2.548-2.805,3.83-4.348c0.607-0.731,1.215-1.463,1.812-2.153
                                              c1.86-2.164,2.688-3.844,3.648-5.79l0.503-1.011c2.344-4.657,0.342-8.587-0.305-9.856c-0.531-1.062-10.012-23.944-11.02-26.348
                                              c-2.424-5.801-5.627-8.502-10.078-8.502c-0.413,0,0,0-1.732,0.073c-2.109,0.089-13.594,1.601-18.672,4.802
                                              c-5.385,3.395-14.495,14.217-14.495,33.249c0,17.129,10.87,33.302,15.537,39.453c0.116,0.155,0.329,0.47,0.638,0.922
                                              c17.873,26.102,40.154,45.446,62.741,54.469c21.745,8.686,32.042,9.69,37.896,9.69c0.001,0,0.001,0,0.001,0
                                              c2.46,0,4.429-0.193,6.166-0.364l1.102-0.105c7.512-0.666,24.02-9.22,27.775-19.655c2.958-8.219,3.738-17.199,1.77-20.458
                                              C233.168,179.508,230.845,178.393,227.904,176.981z"/>
                                              <path id="XMLID_470_" d="M156.734,0C73.318,0,5.454,67.354,5.454,150.143c0,26.777,7.166,52.988,20.741,75.928L0.212,302.716
                                              c-0.484,1.429-0.124,3.009,0.933,4.085C1.908,307.58,2.943,308,4,308c0.405,0,0.813-0.061,1.211-0.188l79.92-25.396
                                              c21.87,11.685,46.588,17.853,71.604,17.853C240.143,300.27,308,232.923,308,150.143C308,67.354,240.143,0,156.734,0z
                                              M156.734,268.994c-23.539,0-46.338-6.797-65.936-19.657c-0.659-0.433-1.424-0.655-2.194-0.655c-0.407,0-0.815,0.062-1.212,0.188
                                              l-40.035,12.726l12.924-38.129c0.418-1.234,0.209-2.595-0.561-3.647c-14.924-20.392-22.813-44.485-22.813-69.677
                                              c0-65.543,53.754-118.867,119.826-118.867c66.064,0,119.812,53.324,119.812,118.867
                                              C276.546,215.678,222.799,268.994,156.734,268.994z"/>
                                          </g>
                                    </svg>
                                </a>
                            </div>
                            <div class="title text-sm font-semibold">
                                <a href="https://wa.me/1871955556">WhatsApp</a>
                            </div>
                        </div>
                        <div class="menu-item help-line flex gap-5 items-center ml-14">
                            <div class="icon">
                                <a href="tel:09638009008">
                                    <svg class="mt-2" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                         viewBox="0 0 24 24" fill="none">
                                        <path
                                            d="M3.21778 6.92444C4.49778 9.44 6.56 11.5022 9.07556 12.7822L11.0311 10.8267C11.28 10.5778 11.6267 10.5067 11.9378 10.6044C12.9333 10.9333 14 11.1111 15.1111 11.1111C15.3469 11.1111 15.573 11.2048 15.7397 11.3715C15.9064 11.5382 16 11.7643 16 12V15.1111C16 15.3469 15.9064 15.573 15.7397 15.7397C15.573 15.9064 15.3469 16 15.1111 16C11.1034 16 7.25983 14.4079 4.42594 11.5741C1.59206 8.74017 0 4.89661 0 0.888889C0 0.653141 0.0936505 0.427048 0.260349 0.260349C0.427048 0.0936505 0.653141 0 0.888889 0H4C4.23575 0 4.46184 0.0936505 4.62854 0.260349C4.79524 0.427048 4.88889 0.653141 4.88889 0.888889C4.88889 2 5.06667 3.06667 5.39556 4.06222C5.49333 4.37333 5.42222 4.72 5.17333 4.96889L3.21778 6.92444Z"
                                            fill="#D71920"/>
                                    </svg>
                                </a>
                            </div>
                            <div class="title text-sm font-semibold">
                                <a href="tel:09638009008"> 09638009008</a>
                            </div>
                        </div>
                        <div class="menu-item ml-14">
                            <div class="social-icons flex gap-8 items-center">
                                <a target="_blank" href="https://www.facebook.com/returntrip.com.bd">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="22" viewBox="0 0 11 22"
                                         fill="none">
                                        <path
                                            d="M6.6721 7.21043V5.3054C6.6721 5.01862 6.69206 4.7933 6.71202 4.64991C6.73198 4.48603 6.79186 4.34264 6.89166 4.19925C6.99146 4.05587 7.13118 3.95344 7.33078 3.89199C7.53038 3.83054 7.80982 3.81006 8.14914 3.81006H10.0054V0H7.03138C5.31481 0 4.09725 0.409683 3.35872 1.24953C2.6202 2.08939 2.24096 3.29795 2.24096 4.93669V7.21043H0.0253906V11H2.24096V22H6.6721V11H9.62619L10.0254 7.21043H6.6721Z"
                                            fill="#D71920"/>
                                    </svg>
                                </a>
                                <a target="_blank" href="https://www.linkedin.com/company/return-trip/">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                         fill="#D71920" stroke="#D71920" stroke-width="2" stroke-linecap="round"
                                         stroke-linejoin="round" class="feather feather-linkedin">
                                        <path
                                            d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path>
                                        <rect x="2" y="9" width="4" height="12"></rect>
                                        <circle cx="4" cy="4" r="2"></circle>
                                    </svg>
                                </a>
                                <a target="_blank" href="https://www.youtube.com/channel/UC1-heJWk9Kr6ziJiNsLUHEA">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="29" height="20" viewBox="0 0 29 20"
                                         fill="none">
                                        <path
                                            d="M28.011 7.64988C28.011 7.09832 27.963 6.3789 27.8671 5.51559C27.7952 4.65228 27.6753 3.88489 27.5314 3.21343C27.3635 2.44604 27.0038 1.82254 26.4522 1.29496C25.9007 0.767386 25.2532 0.479616 24.5098 0.383693C22.2076 0.119904 18.7064 0 14.0302 0C9.35393 0 5.85273 0.119904 3.55057 0.383693C2.80717 0.479616 2.15968 0.767386 1.60812 1.29496C1.05657 1.82254 0.696853 2.44604 0.528988 3.21343C0.361122 3.88489 0.265199 4.65228 0.169276 5.51559C0.0973331 6.3789 0.0493714 7.09832 0.0253906 7.64988C0.0253906 8.22542 0.0253906 8.99281 0.0253906 10C0.0253906 11.0072 0.0253906 11.7746 0.0493714 12.3501C0.0493714 12.9017 0.0973331 13.6211 0.193256 14.4844C0.241218 15.3477 0.361122 16.1151 0.505007 16.7866C0.672873 17.554 1.03258 18.1775 1.58414 18.705C2.1357 19.2326 2.78318 19.5204 3.52659 19.6163C5.82875 19.8801 9.32995 20 14.0062 20C18.6825 20 22.1837 19.8801 24.4858 19.6163C25.2292 19.5444 25.8767 19.2326 26.4283 18.705C26.9798 18.1775 27.3395 17.554 27.5074 16.7866C27.6753 16.1151 27.7712 15.3477 27.8671 14.4844C27.9391 13.6211 27.987 12.9017 28.011 12.3501C28.011 11.7986 28.035 11.0072 28.035 10C28.035 8.99281 28.035 8.22542 28.011 7.64988ZM19.5698 10.8393L11.5602 15.8513C11.4163 15.9472 11.2484 16.0192 11.0326 16.0192C10.8647 16.0192 10.7208 15.9712 10.553 15.8993C10.2172 15.7074 10.0254 15.4197 10.0254 15.012V5.01199C10.0254 4.60432 10.1933 4.31655 10.553 4.1247C10.9127 3.93285 11.2484 3.95683 11.5602 4.14868L15.565 6.65468L19.5698 9.16067C19.8815 9.32854 20.0494 9.61631 20.0494 10C20.0254 10.3837 19.8815 10.6715 19.5698 10.8393Z"
                                            fill="#D71920"/>
                                    </svg>
                                </a>
                            </div>
                        </div>

                        <div
                            class="logout-rtb h-10 w-4/5  px-5 flex gap-4 items-center justify-center absolute left-0 right-0 bottom-5 mx-auto cursor-pointer"
                            v-if="user"
                            @click="logOut()"
                        >
                            <span class="text-white text-sm font-semibold">লগ আউট</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19" viewBox="0 0 21 19"
                                 fill="none">
                                <path
                                    d="M18.4412 9.49989H8.38235M16.3235 12.6764L19.5 9.49989L16.3235 6.32341M11.0294 4.20577V3.14694C11.0294 2.58531 10.8063 2.04668 10.4092 1.64954C10.012 1.25241 9.4734 1.0293 8.91177 1.0293H3.61765C3.05601 1.0293 2.51738 1.25241 2.12024 1.64954C1.72311 2.04668 1.5 2.58531 1.5 3.14694V15.8528C1.5 16.4145 1.72311 16.9531 2.12024 17.3502C2.51738 17.7474 3.05601 17.9705 3.61765 17.9705H8.91177C9.4734 17.9705 10.012 17.7474 10.4092 17.3502C10.8063 16.9531 11.0294 16.4145 11.0294 15.8528V14.794"
                                    stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex-shrink-0 w-24" @click="sidebarOpen = false">
                <!-- Force sidebar to shrink to fit close icon -->
            </div>
        </div>
    </div>
    <!-- Off-canvas menu for mobile -->
    <!-- Menubar for desktop -->
    <div class="w-full md:flex hidden h-24 shadow mb-2">
        <div class="w-full max-w-7xl mx-auto flex justify-between ">
            <div class="flex justify-start items-center">
                <router-link to="/">
                    <img class="w-40" src="/images/aside-square-black.png" alt="return trip bd"/>
                </router-link>
            </div>

            <div class="flex justify-end items-center">
                <SwitchLang class="px-3"/>

                <router-link to="/" :class="navClass">
                    {{ $t("menu.home") }}
                </router-link>
                <router-link
                    v-if="user"
                    to="/trip-request" :class="navClass">
                    {{ $t("menu.createTrip") }}
                </router-link>
                <router-link
                    v-for="(menu, i) in menus"
                    :key="i"
                    :to="{ name: menu.link }"
                    :class="navClass"
                >
                    {{ menu.name }}
                </router-link>
                <div
                    v-if="user"
                    @click="viewNotification()"
                    :class="navClass"
                    class="relative notification-bell flex justify-center cursor-pointer"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                        <path
                            d="M17.9383 22C17.9385 22.5046 17.7479 22.9906 17.4048 23.3605C17.0617 23.7305 16.5915 23.9572 16.0883 23.995L15.9383 24H13.9383C13.4337 24.0002 12.9478 23.8096 12.5778 23.4665C12.2078 23.1234 11.9812 22.6532 11.9433 22.15L11.9383 22H17.9383ZM14.9383 5C16.7533 4.99997 18.4973 5.70489 19.8026 6.96607C21.1078 8.22726 21.8721 9.94609 21.9343 11.76L21.9383 12V15.764L23.7603 19.408C23.8398 19.567 23.8796 19.7429 23.8763 19.9206C23.873 20.0984 23.8266 20.2727 23.7412 20.4286C23.6558 20.5845 23.5338 20.7174 23.3858 20.8158C23.2378 20.9143 23.0681 20.9754 22.8913 20.994L22.7763 21H7.10033C6.92249 21.0001 6.74729 20.957 6.58975 20.8745C6.4322 20.792 6.297 20.6725 6.19574 20.5264C6.09448 20.3802 6.03017 20.2116 6.00833 20.0351C5.98649 19.8586 6.00776 19.6795 6.07033 19.513L6.11633 19.408L7.93833 15.764V12C7.93833 10.1435 8.67582 8.36301 9.98858 7.05025C11.3013 5.7375 13.0818 5 14.9383 5Z"
                            fill="white"/>
                    </svg>
                    <sup
                        v-if="notifications.length > 0"
                        class="count text-gray-700 text-sm mt-2 -ml-2"
                    >
                        {{ notifications.length > 9 ? "9+" : notifications.length }}
                    </sup>
                </div>

                <div
                    class="flex mr-3 items-center relative signin-signup-wrapper"
                    v-if="!user"
                >
                    <button
                        @click="$router.push('/signup')"
                        class="text-primary ml-1 py-1 px-3 rounded header-signin-signup border-solid border-2"
                    >
                        {{ $t("card.signIn") }}
                    </button>
                    <div class="absolute text-sm text-gray-700 flex signup-section">
                        New User?
                        <span
                            class="signup colored-primary cursor-pointer"
                            @click="$router.push('/signup')"
                        >
                            {{ $t("card.signUp") }}
                        </span>
                    </div>
                </div>

                <div class="relative" v-if="user">
                    <AccountDropdown
                        @onClickAccountDropdown="onClickAccountDropdown"
                        :userType="userType"
                    />
                </div>
            </div>

        </div>
    </div>
    <!-- Menubar for desktop -->
    <!-- Hamburger -->
    <div
        class="md:hidden mobile-header h-16 px-4 flex items-center"
        :class="user ? 'justify-between' : 'justify-start mobile-header-bg-white'"
    >
        <div v-if="!user">
            <div
                v-if="$route.name !== 'BeforeSignin'"
                @click="$router.go(-1)"
                class="flex items-center justify-center cursor-pointer"
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                    <path d="M16 2C13.2311 2 10.5243 2.82109 8.22202 4.35943C5.91973 5.89777 4.12532 8.08427 3.06569 10.6424C2.00607 13.2006 1.72882 16.0155 2.26901 18.7313C2.80921 21.447 4.14258 23.9416 6.10051 25.8995C8.05845 27.8574 10.553 29.1908 13.2687 29.731C15.9845 30.2712 18.7994 29.9939 21.3576 28.9343C23.9157 27.8747 26.1022 26.0803 27.6406 23.778C29.1789 21.4757 30 18.7689 30 16C30 12.287 28.525 8.72601 25.8995 6.1005C23.274 3.475 19.713 2 16 2ZM24 17H11.85L17.43 22.573L16 24L8.00001 16L16 8L17.43 9.393L11.85 15H24V17Z" fill="#D71920"/>
                </svg>
            </div>
        </div>
        <div v-if="$route.name === 'TripBidding' && user ">
            <div
                @click="$router.go(-1)"
                class="flex items-center justify-center cursor-pointer h-6 w-6 bg-white rounded-full"
            >
                <span class="mdi mdi-arrow-left mdi-24px text-red-700"></span>
            </div>
        </div>
            <div
                v-if="user && $route.name !== 'TripBidding'"
                @click.stop="toggleSidebar(true)"
                class="-mt-1"
                :class="[user ? 'cursor-pointer': 'cursor-not-allowed']"
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                    <path
                        d="M24.6 13.5H5.4C4.572 13.5 4.5 14.1705 4.5 15C4.5 15.8295 4.572 16.5 5.4 16.5H24.6C25.428 16.5 25.5 15.8295 25.5 15C25.5 14.1705 25.428 13.5 24.6 13.5ZM24.6 19.5H5.4C4.572 19.5 4.5 20.1705 4.5 21C4.5 21.8295 4.572 22.5 5.4 22.5H24.6C25.428 22.5 25.5 21.8295 25.5 21C25.5 20.1705 25.428 19.5 24.6 19.5ZM5.4 10.5H24.6C25.428 10.5 25.5 9.8295 25.5 9C25.5 8.1705 25.428 7.5 24.6 7.5H5.4C4.572 7.5 4.5 8.1705 4.5 9C4.5 9.8295 4.572 10.5 5.4 10.5Z"
                        fill="white"/>
                </svg>
            </div>
            <router-link v-if="user" to="/" class="rtb-title flex items-center text-xl font-bold">
                {{ $route.name === 'TripBidding' ? "বিড সমূহ" : "রিটার্ন ট্রিপ" }}
            </router-link>
            <div class="flex gap-2 items-center">
                <div
                    v-if="user"
                    @click="viewNotification()"
                    class="notification-bell relative flex justify-center cursor-pointer"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                        <path
                            d="M17.9383 22C17.9385 22.5046 17.7479 22.9906 17.4048 23.3605C17.0617 23.7305 16.5915 23.9572 16.0883 23.995L15.9383 24H13.9383C13.4337 24.0002 12.9478 23.8096 12.5778 23.4665C12.2078 23.1234 11.9812 22.6532 11.9433 22.15L11.9383 22H17.9383ZM14.9383 5C16.7533 4.99997 18.4973 5.70489 19.8026 6.96607C21.1078 8.22726 21.8721 9.94609 21.9343 11.76L21.9383 12V15.764L23.7603 19.408C23.8398 19.567 23.8796 19.7429 23.8763 19.9206C23.873 20.0984 23.8266 20.2727 23.7412 20.4286C23.6558 20.5845 23.5338 20.7174 23.3858 20.8158C23.2378 20.9143 23.0681 20.9754 22.8913 20.994L22.7763 21H7.10033C6.92249 21.0001 6.74729 20.957 6.58975 20.8745C6.4322 20.792 6.297 20.6725 6.19574 20.5264C6.09448 20.3802 6.03017 20.2116 6.00833 20.0351C5.98649 19.8586 6.00776 19.6795 6.07033 19.513L6.11633 19.408L7.93833 15.764V12C7.93833 10.1435 8.67582 8.36301 9.98858 7.05025C11.3013 5.7375 13.0818 5 14.9383 5Z"
                            fill="white"/>
                    </svg>
                    <sup
                        v-if="notifications.length > 0"
                        class="count text-gray-700 text-sm mt-2 -ml-2"
                    >
                        {{ notifications.length > 9 ? "9+" : notifications.length }}
                    </sup>
                </div>
            </div>
        </div>
        <!-- Hamburger -->
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import AccountDropdown from "@/components/molecule/auth/AccountDropdown";
import {menus} from "@/data/menu";
import SwitchLang from "@/components/atom/SwitchLang";
import Form from "@/services/form";
import axios from "axios";
import {clearDeviceInterests} from "@/services/clearDeviceInterests";
import {directive} from "vue3-click-away";

export default {
    name: "Header",
    components: {
        AccountDropdown,
        SwitchLang,
    },

    data() {
        return {
            isLoggingOut: false,
            notifications: [],
            form: new Form(),
            navClass: [
                "px-4",
                "font-semibold",
                "hover:text-primary",
                "text-gray-700",
                "text-sm",
            ],
            mobileNav:
                "group flex items-center px-2 py-2 mb-2 text-base leading-6 font-medium rounded-md text-black focus:outline-none focus:bg-gray-700 transition ease-in-out duration-150",
            menus,
            signin: true,
        };
    },
    directives: {
        ClickAway: directive
    },

    computed: {
        ...mapGetters({
            user: "user/getUser",
            sidebarOpen: "getSidebarStatus",
        }),

        userType() {
            return this.user ? this.user?.type : false;
        },
    },

    methods: {
        ...mapMutations({
            setUserInfo: "user/setLoggedInUser",
            setDialogComponent: "setDialogComponent",
            setSidebarStatus: "setSidebarStatus",
            setDialog: "setDialog",
        }),
        navigate(link) {
            this.setSidebarStatus(false);
            this.$router.push(link);
        },
        handleDialog(name) {
            this.setSidebarStatus(false);
            this.setDialogComponent(name);
        },
        onClickAccountDropdown() {
            this.setSidebarStatus(false);
        },

        toggleSidebar(value) {
            this.setSidebarStatus(value);
        },
        async logOut() {
            localStorage.removeItem("trip_auth_user");
            localStorage.removeItem("token");
            localStorage.removeItem("imp_token");
            clearDeviceInterests();
            this.isLoggingOut = true;
            try {
                await this.form.post("auth/logout");
            } catch (err) {
                if (err.response) {
                }
            } finally {
                this.setUserInfo(null);
                this.$router.push("/signup");
            }
        },
        viewNotification() {
            this.$router.push("/notifications");
        },
        getNotifications(url) {
            axios.get(url).then((res) => {
                this.notifications = res.data.data.data;
            });
        },

        onClickAway() {
            if (this.sidebarOpen) {
                this.toggleSidebar(!this.sidebarOpen)
            }
        }
    }, //end of methods
    mounted() {
        let url = "notifications/unseen";
        if (this.user) {
            this.getNotifications(url);
        }
    },
    props: {
        logo: {
            default: "/images/aside-square-black.png",
        },
    },

    created() {
        this.setUserInfo();
    },

    watch: {
        $route: function () {
            this.setSidebarStatus(false);
            this.setDialog(false);
        },
    },
};
</script>

<style scoped lang="scss">
.rtb-header {
    background: #fff;
    @apply shadow-lg md:hidden block;
    font-family: 'Noto Sans Bengali';
}

.signin-signup-wrapper {
    min-width: 130px;
    display: grid;
    justify-content: flex-end;
}

.header-signin-signup {
    border-color: #ff4365;
    min-width: 120px;
}

.colored-primary {
    color: #ff4365;
}

.signup {
    font-weight: bold;
}

.signup-section {
    font-size: 12px !important;
    gap: 5px;
    bottom: -20px !important;
}

.vertical-menu-dots {
    max-height: 24px;
    max-width: 24px;
}

.vsc-popup-button--default {
    background-color: #69c157 !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.vsc-popup {
    bottom: 50px !important;
}

.vsc-popup-button {
    height: 40px !important;
    width: 40px !important;
}

.vsc-popup-button:focus,
.vsc-popup-button:hover {
    box-shadow: none !important;
}

.max-width {
    max-width: 1260px;
}

.contact-us p {
    border-bottom: 3px solid #000;
    max-width: fit-content;
    padding-right: 10px;
    padding-bottom: 2px;
}

.mobile-header {
    font-family: 'Noto Sans Bengali';
    background: #D72028;

    .rtb-title {
        color: #ffffff;
    }
}

.mobile-header-bg-white {
    background: #F8FAFC;
}

.mid-line {
    height: 2px;
    width: 100%;
    margin: 0 10px;
    border-radius: 10px;
    background: #BBC6D0;
}

.user-name-email {
    color: #3A3A3A;

    .email {
        font-size: 12px;
        font-weight: 400;
    }
}

.menu-item {
    color: #3A3A3A;
}

.logout-rtb {
    border-radius: 10px;
    background: #D71920;
}

@media (min-width: 640px) {
    .notification-bell svg {
        height: 30px;
        width: 30px;

        path {
            fill: gray;
        }
    }
}
</style>
