<template>
    <div>
      <div class="drawer" :class="{ 'is-open': isOpen, 'is-visible': isVisible }">
        <div
          class="drawer__overlay"
          :style="{ transitionDuration: `${speed}ms` }"
        ></div>
        <div
          class="drawer__content"
          v-click-away="closeDrawer"
          :style="{
            maxWidth: maxWidth,
            maxHeight: maxHeight,
            transitionDuration: `${speed}ms`,
            backgroundColor: backgroundColor,
          }"
        >
          <slot></slot>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { directive } from "vue3-click-away";
  
  export default {
    name: "Drawer",
  
    directives: {
      ClickAway: directive,
    },
  
    props: {
      isOpen: {
        type: Boolean,
        required: false,
        default: false,
      },
  
      maxWidth: {
        type: String,
        required: false,
        default: "448px",
      },
      maxHeight: {
        type: String,
        required: false,
        default: "600px"
      },
  
      // Transition Speed in Milliseconds
      speed: {
        type: Number,
        required: false,
        default: 300,
      },
  
      backgroundColor: {
        type: String,
        required: false,
        default: "#fafafa",
      },
    },
  
    data() {
      return {
        isVisible: false,
        isTransitioning: false,
      };
    },
  
    watch: {
      isOpen(val) {
        this.isTransitioning = true;
  
        if (val) {
          this.toggleBackgroundScrolling(true);
          this.isVisible = true;
        } else {
          this.toggleBackgroundScrolling(false);
          setTimeout(() => (this.isVisible = false), this.speed);
        }
  
        setTimeout(() => (this.isTransitioning = false), this.speed);
      },
    },
  
    methods: {
      toggleBackgroundScrolling(enable) {
        const body = document.querySelector("body");
  
        body.style.overflow = enable ? "hidden" : null;
      },
  
      closeDrawer() {
        if (!this.isTransitioning) {
          this.$emit("close");
        }
      },
    },
  
    mounted() {
      this.isVisible = this.isOpen;
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .drawer {
    visibility: hidden;
  
    &.is-visible {
      visibility: visible;
    }
  
    &.is-open {
      .drawer__overlay {
        opacity: 0.5;
      }
  
      .drawer__content {
        transform: translateY(0);
      }
    }
  
    &__overlay {
      position: fixed;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 200;
      opacity: 0;
      transition-property: opacity;
      background-color: #000000;
      user-select: none;
    }
  
    &__content {
      position: fixed;
      bottom: 0;
      margin: 0 auto;
      height: 100%;
      width: 100%;
      z-index: 9999;
      overflow: auto;
      transition-property: transform;
      display: flex;
      flex-direction: column;
      transform: translateY(100%);
      box-shadow: 0 2px 6px #777;
      border-radius: 10px 10px 0px 0px;
      background: #FFF;
    }
  }
  </style>