import { createStore } from "vuex";
import user from "./modules/user";
import driver from "./modules/driver";
import car from "./modules/car";
import trip from "./modules/trip";
import feeds from "./modules/feeds";
import bookings from "./modules/bookings";

export default createStore({
	modules: {
		user,
		driver,
		car,
		trip,
		feeds,
		bookings,
	},

	state: {
		showDialog: false,
		sidebarOpen: false,
		tripId: "",
		carId: "",
		carDetails: {},
		toasterMessage: null,
		dialogComponent: "LoginComponent",
		apiProgress: 0,
		bookingIndex: "",
		siteData: {},
		tripSearchQuery: {},
		language: "",
		showWallet: false,
		showPriceModal: false,
		showOtherBid:false,
		walletData: null,
		showRating: false,
		biddingCommission: 0.10,
		selectedTrip: null
	},

	getters: {
		showDialog: (state) => state.showDialog,
		getSidebarStatus: (state) => state.sidebarOpen,
		toasterMessage: (state) => state.toasterMessage,
		dialogComponent: (state) => state.dialogComponent,
		getProgress: (state) => state.apiProgress,
		getSiteData: (state) => state.siteData,
		getBookingIndex: (state) => state.bookingIndex,
		getTripSearchQuery: (state) => state.tripSearchQuery,
		getSelectedTrip: (state) => state.selectedTrip
	},

	mutations: {
		setBookingIndex: (state, value) => (state.bookingIndex = value),
		setTripId: (state, value) => (state.tripId = value),
		setSidebarStatus: (state, value) => (state.sidebarOpen = value),
		setCarId: (state, value) => (state.CarId = value),
		setCar: (state, value) => (state.carDetails = value),
		setCloseToaster: (state) => (state.toasterMessage = null),
		setSiteData: (state, value) => (state.siteData = value),
		setTripSearchQuery: (state, data) =>
			(state.tripSearchQuery[data.key] = data.value),

		setDialogComponent: (state, value) => {
			state.dialogComponent = value.name ?? value;
			state.showDialog = true;
			if(value.trip){
				state.selectedTrip = value.trip
			}
		},
		setToaster: (state, payload) => {
			state.toasterMessage = payload;
			setTimeout(() => {
				state.toasterMessage = null;
			}, 4000);
		},

		setDialog: (state, value) => {
			state.showDialog = value;
			state.selectedTrip = null
		},

		setWalletData: (state, value) => {
			state.walletData = value;
		},

		toogleWallet: (state) => {
			state.showWallet = !state.showWallet;
		},
		togglePriceModal: (state) => {
			state.showPriceModal = !state.showPriceModal
		},
        toogleRating: (state) => {
            state.showRating = !state.showRating
		},
		toggleOtherBid: (state) => {
			state.showOtherBid = !state.showOtherBid
		},
		updateProgress: (state, value) => (state.apiProgress = value),
	},
});
