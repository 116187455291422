export const testimonials = [
	{
		statement: 'আমি আশা করি এই প্লাটফর্ম দ্বারা অনেক মানুষ উপকৃত হবে। সবার উচিত রিটার্ন ট্রিপ বিডিকে উৎসাহ দিয়ে এগিয়ে নিয়ে যাওয়া। Good service',
		name: 'MD Abdur Rahman',
		position: '',
		image: '/images/user-avatar.png'
	},
	{
		statement: 'Hi It\'s a very good app. This means no need to worry about going to any district anytime. And any problem can be solved immediately if you contact the authorities. Thank you very much for creating such a nice service app.',
		name: 'Emdad Khan',
		position: '',
		image: '/images/user-avatar.png'
	},
	{
		statement: 'Thanks a lot Return Trip for their service.. we safely reached at our destination... Thanks Again.. Timing, vachel quality, driver behavior all is good.',
		name: 'Munir Kabir',
		position: '',
		image: '/images/user-avatar.png'
	},
	{
		statement: 'I have taken 2 times service from Return Trip till now. Very cost effective and reasonable service i have had experienced each time. They are really trustable, reliable and professional car rental service provider. They have quick responsive customer service team',
		name: 'Hasiv Md. Musaddek',
		position: ' ',
		image: '/images/user-avatar.png'
	}
]