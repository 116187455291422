<template>
  <div class="countdown-rtb">
     {{ minutes+ ":"+ seconds }}
  </div>
</template>

<script>
export default {
   data(){
    return {
        countdown: null,
        hours: 0,
        minutes: 0,
        seconds: 0,
    }
   },
   props: {
		trip: {
			type: Object,
			default: "",
		}
	},

	mounted() {
        this.countdown = setInterval(() => {
            const now = new Date().getTime();
            const journey_date_time = new Date(this.trip.journey_date_time).getTime();
            const created_at = new Date(this.trip.created_at).getTime();
            const hours_in_ms = 60 * 60 * 1000;
            const extended_time = 80 * 60 * 1000;
            let update_created_at = 0;
            if (this.trip.status === 'active') {
                update_created_at = created_at + hours_in_ms;
            }
            else {
                update_created_at = created_at + extended_time;
            }

            let distance = 0;
            if (update_created_at < journey_date_time) {
                distance = update_created_at - now;
            }
            else {
                distance = journey_date_time - now;
            }
            
            this.hours = Math.floor(distance / (1000 * 60 * 60));
            this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            this.seconds = Math.floor((distance % (1000 * 60)) / 1000);

            // Add leading zero to hours, minutes, and seconds if they are single digits
            this.hours = this.hours < 10 ? "0" + this.hours : this.hours;
            this.minutes = this.minutes < 10 ? "0" + this.minutes : this.minutes;
            this.seconds = this.seconds < 10 ? "0" + this.seconds : this.seconds;

            if (distance < 0) {
                clearInterval(this.countdown);
                this.hours = 0;
                this.minutes = 0;
                this.seconds = 0;
            }
        }, 1000);
    },
    beforeDestroy() {
        clearInterval(this.countdown);
    }
}
</script>

<style>

</style>