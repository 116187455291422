<template>
	<div class="ride-details">
		<div
			class="mt-10 min-h-[180px]  relative pb-3"
            :class="trip?.type == 'round' ? 'border-2 border-red-500 rounded bg-red-100 shadow-cyan-500/50' : 'shadow-card bg-white rounded-t-md'"
		>
			<div class="flex justify-between items-center rounded-t-md bg-whiteBlue">
				<div class="flex justify-between items-center">
					<div
						class="bg-rose rounded-tl-md pl-4 py-2 text-xs relative text-white uppercase font-sans border-r-14 border-rose"
					>
                        <span class="mdi mdi-calendar text-mr-1"></span>
						{{ trip?.journey_date }}

                        <span class="mdi mdi-clock mr-1"></span>
						{{ trip?.journey_time }}
						<div
							class="absolute top-0 -right-6 bg-rose w-5 h-full transform -skew-x-30"
						></div>
					</div>
					<div class="hidden md:flex items-center pl-8">
                    <!--<div class="mr-3">
							<span class="mdi mdi-calendar-blank text-mr-1"></span>
							<span class="text-bluishBlack font-medium uppercase text-sm">{{
								trip?.journey_date
							}}</span>
						</div>
						<div>
							<span class="mdi mdi-clock-time-five text-primary mr-1"></span>
							<span class="text-bluishBlack font-medium uppercase text-sm">{{
								trip?.journey_time
							}}</span>
						</div>-->
					</div>

					<div
						class="hidden md:flex items-center mx-4"
						v-if="trip && trip?.type == 'round'"
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="16"
							height="16"
							viewBox="0 0 24 24"
							fill="none"
							stroke="currentColor"
							stroke-width="2"
							stroke-linecap="round"
							stroke-linejoin="round"
							class="feather feather-repeat round-signal"
						>
							<polyline points="17 1 21 5 17 9"></polyline>
							<path d="M3 11V9a4 4 0 0 1 4-4h14"></path>
							<polyline points="7 23 3 19 7 15"></polyline>
							<path d="M21 13v2a4 4 0 0 1-4 4H3"></path>
						</svg>
						<div class="mr-3">
							<span class="mdi mdi-calendar-blank text-mr-1"></span>
							<span class="text-bluishBlack font-medium uppercase text-sm">{{
								trip?.return_date
							}}</span>
						</div>
						<div>
							<span class="mdi mdi-clock-time-five text-primary mr-1"></span>
							<span class="text-bluishBlack font-medium uppercase text-sm">{{
								trip?.return_time
							}}</span>
						</div>
					</div>
				</div>
				<div class="pr-2 flex justify-between space-x-2">
					<div class="notification inline-block px-2 sm:px-4 md:px-6">
						<slot name="notification"></slot>
					</div>
                    <p v-if="trip.status == 'active'" class="bg-rose text-white text-center text-xs px-3 py-1 rounded-sm">
                        <span class="mdi mdi-clock-alert mr-1"></span>
                        <span>{{ hours }}:{{ minutes }}:{{ seconds }}</span>
                    </p>
                    <!--<p v-if="trip.status == 'completed' &&  trip?.own_rating != null" class="bg-blue-400 text-gray-100 text-center text-xs px-3 py-1 rounded-sm">
                        <span class="mdi mdi-star text-yellow-200"></span>
                        {{ trip?.own_rating != null ? trip?.own_rating?.rating :  "Not Rated"  }}
                    </p>-->

					<Status :status="trip.status" />
					<p
						v-if="routeName != 'Home' && trip?.price"
						class="text-pri-sm sm:text-pri-xs md:text-pri-md inline-block"
					>
						<span class="px-2 text-pri-md sm:text-pri-xs"
							>৳{{ parseInt(trip.price) }}
							</span
						>
					</p>
				</div>
			</div>

			<div class="flex md:hidden px-2 mt-4">
            <!--<div class="mr-3">
					<span class="mdi mdi-calendar-blank text-coolBlue mr-1"></span>
					<span class="text-bluishBlack font-medium uppercase text-sm">{{
						trip?.journey_date
					}}</span>
				</div>
				<div>
					<span class="mdi mdi-clock-time-five text-coolBlue mr-1"></span>
					<span class="text-bluishBlack font-medium uppercase text-sm">{{
						trip?.journey_time
					}}</span>
					<span
						class="bg-coolBlue ml-1 rounded px-1 text-white"
						v-if="trip?.type == 'round'"
						>start</span
					>
				</div>-->
			</div>

			<div
				class="flex md:hidden px-2 mt-4"
				v-if="trip && trip?.type == 'round'"
			>
				<div class="mr-3">
					<span class="mdi mdi-calendar-blank text-primary mr-1"></span>
					<span class="text-bluishBlack font-medium uppercase text-sm">{{
						trip?.return_date
					}}</span>
				</div>
				<div>
					<span class="mdi mdi-clock-time-five text-primary mr-1"></span>
					<span class="text-bluishBlack font-medium uppercase text-sm">{{
						trip?.return_time
					}}</span>
                    ({{ $t("returnDateTime") }})
				</div>
			</div>

			<div
				class="hidden md:flex gap-10 py-10 px-5"
			>
				<div class="">
					<div class="font-semibold text-gray-400 text-sm">
						{{ $t("triCreateForm.pickupLocation") }}
					</div>

					<div
						class="text-bluishBlack text-sm tracking-widest font-medium my-2 break-words"
						v-if="
							user &&
							user?.type == 'provider' &&
							trip &&
							trip.geo_location &&
							trip.geo_location.location
						"
					>
                        {{ trip?.pickup_full_address }},
						{{
							$store.state.language == "en"
								? trip?.geo_location?.location?.pick_district?.coordinate?.name
										?.en
								: trip?.geo_location?.location?.pick_district?.coordinate?.name
										?.bn
						}}
					</div>

					<div
						class="text-bluishBlack text-sm tracking-widest font-medium my-2 break-words"
						v-if="
							user &&
							user?.type == 'customer' &&
							trip &&
							trip.geo_location &&
							trip.geo_location.pick_geo_loc
						"
					>
                        {{ trip?.pickup_full_address }},
						{{
							$store.state.language == "en"
								? trip?.geo_location?.pick_geo_loc?.district?.name?.en
								: trip?.geo_location?.pick_geo_loc?.district?.name?.bn
						}}
					</div>
				</div>
				<div class="">
					<div class="font-semibold text-gray-400 text-sm">
						{{ $t("triCreateForm.destinationLocation") }}
					</div>
					<div
						class="text-bluishBlack text-sm tracking-widest font-medium my-2 break-words"
						v-if="
							user &&
							user?.type == 'provider' &&
							trip &&
							trip.geo_location &&
							trip.geo_location.location
						"
					>
                        {{ trip?.destination_full_address }},
						{{
							$store.state.language == "en"
								? trip?.geo_location?.location?.dest_district?.coordinate?.name
										?.en
								: trip?.geo_location?.location?.dest_district?.coordinate?.name
										?.bn
						}}
					</div>

					<div
						class="text-bluishBlack text-sm tracking-widest font-medium my-2 break-words"
						v-if="
							user &&
							user?.type == 'customer' &&
							trip &&
							trip.geo_location &&
							trip.geo_location.dest_geo_loc
						"
					>
                        {{ trip?.destination_full_address }},
						{{
							$store.state.language == "en"
								? trip?.geo_location?.dest_geo_loc?.district?.name?.en
								: trip?.geo_location?.dest_geo_loc?.district?.name?.bn
						}}
					</div>
				</div>

				<div class="">
					<div class="font-semibold text-gray-400 text-sm">
						{{ $t("triCreateForm.numberOfPeople") }}
					</div>
					<div
						class="text-bluishBlack capitalize text-sm tracking-widest font-medium my-2"
					>
						{{trip?.car_type+' '+ getTripCarType(trip?.car_type)}} 
					</div>
				</div>

				<div class="">
					<div class="font-semibold text-gray-400 text-sm">
						{{ $t("triCreateForm.type") }}
					</div>
					<div
						class="text-bluishBlack text-sm tracking-widest font-medium my-2 capitalize"
					>
                        <!--{{ trip?.type.replace("e", "e ") }}-->
                        {{ trip?.type == 'oneway' ?  $t("one_way") : $t("round_trip") }}
					</div>
				</div>

				<div
					class=""
					v-if="trip.status == 'hold'"
				>
					<div class="font-semibold text-gray-400 text-sm capitalize">
						{{ trip?.created_by }} Info
					</div>

					<div class="w-full">
						<div class="text-sm font-medium my-2">
							{{ booking?.trip?.user?.name }}
						</div>
						<div
							class="text-sm font-medium my-2 text-blue-500"
							v-if="booking?.trip?.user?.phone"
						>
							<a
								:href="getTel(booking?.trip?.user?.phone)"
								class="flex gap-1 items-center"
							>
								<span class="mdi mdi-phone sm:hidden block"></span>
								{{ getModifiedPhoneNumber(booking?.trip?.user?.phone) }}</a
							>
						</div>
					</div>
				</div>
				<div class="absolute customer-trip-bid" :class="bookedTrip ? 'booked-trip':''">
					<div class="">
						<button
							v-if="showBidingButton && !bookedTrip"
							@click="handlePassengerBookingModal(trip?.id)"
							class="absolute bg-rose text-white text-xs px-6 py-2 rounded w-28 focus:shadow-md focus:outline-none hover:opacity-90 transition duration-500 shadow-btn"
						>
							{{ $t("triCreateForm.bidNow") }}
						</button>
						<button
							v-if="bookedTrip && showBidingButton"
							@click="handlePassengerBookingModal(trip?.id)"
							class="absolute bg-green-500 text-white text-xs px-2 py-2 rounded w-64 focus:shadow-md focus:outline-none hover:opacity-90 transition duration-500 shadow-btn"
						>
							আপনি {{bookedTrip.fixed_price.toLocaleString("bn-BD")}}  টাকায় বিড করেছেন
                            <span class="mdi mdi-arrow-right-thick"></span>
						</button>
					</div>
				</div>
			</div>

			<!-- mobile section -->

			<div class="md:hidden px-3 mt-3">
				<destination-viewer :trip="trip" />
			</div>
			<div class="md:hidden px-3 mt-3">
				<SeatAndTripTypeViewer
					seatOrPassenger="Passengers"
					:type="trip?.type"
					:total="trip?.no_of_seat"
					:carType="trip?.car_type"
				/>
			</div>
			
			<div
			    v-if="trip && trip?.user && trip?.user?.phone && trip.status !== 'completed'"
				class="name-and-mobile md:hidden px-3 mt-3 bg-red-50 p-3 mx-3 rounded text-sm"
			>  
				<div class="name mdi mdi-account">{{ trip?.user?.name }}</div>
				<div
					class="phone text-blue-500"
					v-if="trip && trip?.user && trip?.user?.phone"
				>
					<a :href="getTel(trip?.user?.phone)" class="flex gap-1 items-center">
						<span class="mdi mdi-phone sm:hidden block"></span>
						{{ getModifiedPhoneNumber(trip?.user?.phone) }}</a
					>
				</div>
			</div>
			<div v-if="showCreatorInfo" class="px-3 mt-3">
				<div class="p-3 bg-green-50 boder border-2 border-green-200 md:hidden">
					<p class="text-gray-400 text-sm">{{ $t("your_bidding") }}</p>
					<slot name="biddingInformation"></slot>
				</div>
				<div class="absolute right-2 top-10">
					<slot class="" name="statusChange"></slot>
				</div>
			</div>
			<div v-if="showBidingButton && !bookedTrip" class="md:hidden flex justify-end px-3 h-10">
				<button
					@click="handlePassengerBookingModal(trip.id)"
					class="h-8 mt-4 -mb-10 bg-rose text-white text-xs px-6 py-2 rounded w-28 focus:shadow-md focus:outline-none hover:opacity-90 transition duration-500 shadow-btn"
				>
					{{ $t("triCreateForm.bidNow") }}
				</button>
			</div>
			<div  v-if="showBidingButton && bookedTrip" class="md:hidden flex justify-end px-3 h-10 mt-2">
				<button
							@click="handlePassengerBookingModal(trip?.id)"
							class="absolute bg-green-500 text-white text-xs px-2 py-2 rounded w-64 focus:shadow-md focus:outline-none hover:opacity-90 transition duration-500 shadow-btn"
						>
							 আপনি {{bookedTrip.fixed_price.toLocaleString("bn-BD")}}  টাকায় বিড করেছেন
                    <span class="mdi mdi-arrow-right-thick"></span>
                </button>
			</div>
			<!-- end mobile section -->
		</div>
		<div class="hidden md:block border-b-3 border-red-500"></div>
	</div>
</template>

<script>
import UserInformation from "@/components/atom/UserInformation";
import DestinationViewer from "@/components/atom/DestinationViewer";
import SeatAndTripTypeViewer from "@/components/atom/SeatAndTripTypeViewer";
import Status from "@/components/atom/Status";
import { mapMutations } from "vuex";
import { has } from "lodash";

export default {
	name: "FeedCustomer",
	components: {
		DestinationViewer,
		UserInformation,
		SeatAndTripTypeViewer,
		Status,
	},

	props: {
		booking: {
			type: Object,
			default: {},
		},
		trip: {
			type: Object,
			default: "",
		},
		showBidingButton: {
			type: Boolean,
			default: true,
		},
		showCreatorInfo: {
			type: Boolean,
			default: false,
		},
		showRightmostImage: {
			type: Boolean,
			default: true,
		},
	},

	data() {
		return {
			user: null,
			bookingProvider: '',
			bookedTrip:'',
            countdown: null,
            hours: 0,
            minutes: 0,
            seconds: 0,
		};
	},

	computed: {
		routeName() {
			return this.$route.name;
		},
		requestedPickupLocation() {
			if (has(this.trip, "geo_location.location")) {
				let lang = "en";
				let location = this.trip.geo_location.location;
				let division = "";
				let district = "";
				let sub_district = "";
				let union = "";
				if (
					has(location, `pick_district.meta.division.coordinate.name.${lang}`)
				)
					division =
						location?.pick_district?.meta.division?.coordinate?.name[lang];
				if (has(location, `pick_district.coordinate.name.${lang}`))
					district = location?.pick_district?.coordinate?.name[lang];
				if (has(location, `pick_sub_district.coordinate.name.${lang}`))
					sub_district = location?.pick_sub_district?.coordinate?.name[lang];
				if (has(location, `pick_union.coordinate.name.${lang}`))
					union = location?.pick_union?.coordinate?.name[lang];

				return `${union ? union + ", " : ""}${
					sub_district ? sub_district + ", " : ""
				}${district ? district + ", " : ""}${division}`;
			}

			return "N/A";
		},

		requestedDestinationLocation() {
			if (has(this.trip, "geo_location.location")) {
				let lang = "en";
				let location = this.trip.geo_location.location;
				let division = "";
				let district = "";
				let sub_district = "";
				let union = "";
				if (
					has(location, `dest_district.meta.division.coordinate.name.${lang}`)
				)
					division =
						location?.dest_district?.meta?.division?.coordinate?.name[lang];
				if (has(location, `dest_district.coordinate.name.${lang}`))
					district = location?.dest_district?.coordinate?.name[lang];
				if (has(location, `dest_sub_district.coordinate.name.${lang}`))
					sub_district = location?.dest_sub_district?.coordinate?.name[lang];
				if (has(location, `dest_union.coordinate.name.${lang}`))
					union = location?.dest_union?.coordinate?.name[lang];

				return `${union ? union + ", " : ""}${
					sub_district ? sub_district + ", " : ""
				}${district ? district + ", " : ""}${division}`;
			}

			return "N/A";
		},
	},

	methods: {
		...mapMutations({
			setTripId: "setTripId",
			setDialogComponent: "setDialogComponent",
		}),

		handlePassengerBookingModal(tripId) {
			this.$router.push({ name: "TripBooking", params: { slug: tripId } });
		},
		getModifiedPhoneNumber(number) {
			return number.slice(3);
		},

		getTel(number) {
			return "tel:" + number;
		},

		getTripCarType(type){
			if(type == 'mini micro'){
				return '(7 seats)';
			}
			if(type == 'sedan car'){
				return '(4 seats)';
			}
			if(type == 'micro'){
				return '(10 seats)';
			}
		}
	},

	mounted() {
		var userdata = localStorage.getItem("trip_auth_user");
		userdata = JSON.parse(userdata);
		this.user = userdata;
		this.bookingProvider = JSON.parse(localStorage.getItem('bookingProvider'))
		if(this.bookingProvider && this.bookingProvider.length > 0){
			this.bookedTrip = this.bookingProvider.find(item => item.trip.id === this.trip.id)
		}

        this.countdown = setInterval(() => {
            const now = new Date().getTime();
            const journey_date_time = new Date(this.trip.journey_date_time).getTime();
            const created_at = new Date(this.trip.created_at).getTime();
            const two_hours_in_ms = 2 * 60 * 60 * 1000;
            const update_created_at = created_at + two_hours_in_ms;
            let distance = 0;

            if (update_created_at < journey_date_time) {
                distance = update_created_at - now;
            }
            else {
                distance = journey_date_time - now;
            }

            this.hours = Math.floor(distance / (1000 * 60 * 60));
            this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            this.seconds = Math.floor((distance % (1000 * 60)) / 1000);

            // Add leading zero to hours, minutes, and seconds if they are single digits
            this.hours = this.hours < 10 ? "0" + this.hours : this.hours;
            this.minutes = this.minutes < 10 ? "0" + this.minutes : this.minutes;
            this.seconds = this.seconds < 10 ? "0" + this.seconds : this.seconds;

            if (distance < 0) {
                clearInterval(this.countdown);
                this.hours = 0;
                this.minutes = 0;
                this.seconds = 0;
            }
        }, 1000);
	},
    beforeDestroy() {
        clearInterval(this.countdown);
    }
};
</script>

<style scoped>
@import "./feed-customer-card.styles.css";
.shadow-card {
	min-height: 180px;
}

svg.round-signal {
	color: #e11d48;
	margin-right: 12px;
}

.customer-trip-bid{
	right: 120px;
	bottom: 40px;
}
.customer-trip-bid.booked-trip {
	right: 260px;
}
</style>
