<template>
    <div class="max-width mx-auto">
        <div class="flex flex-col md:flex-row mx-8 md:mx-0">
            <div class="bg-white mb-4 shadow text-center p-4 rounded-lg md:mr-5 md:w-1/3">
                <div class="text-primary1">
                    <span class="mdi mdi-car mdi-36px"> </span>
                </div>
                <div class="text-primary1 font-bold">Trips</div>
                <div class="text-primary1">15K+</div>
                <div class="text-primary1">21% more than last month</div>
            </div>

            <div class="bg-white mb-4 shadow text-center p-4 rounded-lg md:mr-2.5 md:w-1/3">
                <div class="text-secondary">
                    <span class="mdi mdi-account-multiple mdi-36px"></span>
                </div>
                <div class="text-secondary font-bold">Customers</div>
                <div class="text-secondary">50K+</div>
                <div class="text-secondary">21% more than last month</div>
            </div>

            <div class="bg-white mb-4 shadow text-center p-4 rounded-lg md:ml-2.5 md:w-1/3">
                <div class="text-secondary flex justify-center">
                    <span class="mdi mdi-car-connected mdi-36px"></span>
                </div>
                <div class="text-secondary font-bold">providers</div>
                <div class="text-secondary">5K+</div>
                <div class="text-secondary">21% more than last month</div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name:'Stat',
}
</script>