const engToBd = {
    methods: {
        engToBdNum(str) {
            let banglaNumber = {
                0: "০",
                1: "১",
                2: "২",
                3: "৩",
                4: "৪",
                5: "৫",
                6: "৬",
                7: "৭",
                8: "৮",
                9: "৯"
            };
            for (let x in banglaNumber) {
                str = str.replace(new RegExp(x, "g"), banglaNumber[x]);
            }
            return str;
        },

        timeConvertToText(str) {
            let time;
            switch (str) {
                case '00:00:00':
                    time = 'রাত ১২:০০'
                    break;
                case '00:30:00':
                    time = "রাত ১২:৩০"
                    break;
                case '01:00:00':
                    time = "রাত ০১:০০"
                    break;
                case '01:30:00':
                    time = "রাত ০১:৩০"
                    break;
                case '02:00:00':
                    time = "রাত ০২:০০"
                    break;
                case '02:30:00':
                    time = "রাত ০২:৩০"
                    break;
                case '03:00:00':
                    time = "রাত ০৩:০০"
                    break;
                case '03:30:00':
                    time = "রাত ০৩:৩০"
                    break;
                case '04:00:00':
                    time = "রাত ০৪:০০"
                    break;
                case '04:30:00':
                    time = "রাত ০৪:৩০"
                    break;
                case '05:00:00':
                    time = "সকাল ০৫:০০"
                    break;
                case '05:30:00':
                    time = 'সকাল ০৫:৩০';
                    break;
                case '06:00:00':
                    time = "সকাল ০৬:০০"
                    break;
                case '06:30:00':
                    time = 'সকাল ০৬:৩০'
                    break;
                case '07:00:00':
                    time = 'সকাল ০৭:০০'
                    break;
                case '07:30:00':
                    time = 'সকাল ০৭:৩০'
                    break;
                case '08:00:00':
                    time = 'সকাল ০৮:০০'
                    break;
                case '08:30:00':
                    time = 'সকাল ০৮:৩০'
                    break;
                case '09:00:00':
                    time = 'সকাল ০৯:০০'
                    break;
                case '09:30:00':
                    time = 'সকাল ০৯:৩০'
                    break;
                case '10:00:00':
                    time = 'সকাল ১০:০০'
                    break;
                case '10:30:00':
                    time = 'সকাল ১০:৩০'
                    break;
                case '11:00:00':
                    time = 'দুপুর ১১:০০'
                    break;
                case '11:30:00':
                    time = 'দুপুর ১১:৩০'
                    break;
                case '12:00:00':
                    time = 'দুপুর ১২:০০'
                    break;
                case '12:30:00':
                    time = 'দুপুর ১২:৩০'
                    break;
                case '13:00:00':
                    time = 'দুপুর ০১:০০'
                    break;
                case '13:30:00':
                    time = 'দুপুর ০১:৩০'
                    break;
                case '14:00:00':
                    time = 'দুপুর ০২:০০'
                    break;
                case '14:30:00':
                    time = 'দুপুর ০২:৩০'
                    break;
                case '15:00:00':
                    time = 'দুপুর ০৩:০০'
                    break;
                case '15:30:00':
                    time = 'দুপুর ০৩:৩০'
                    break;
                case '16:00:00':
                    time = 'বিকাল ০৪:০০'
                    break;
                case '16:30:00':
                    time = 'বিকাল ০৪:৩০'
                    break;
                case '17:00:00':
                    time = 'বিকাল ০৫:০০'
                    break;
                case '17:30:00':
                    time = 'বিকাল ০৫:৩০'
                    break;
                case '18:00:00':
                    time = 'বিকাল ০৬:০০'
                    break;
                case '18:30:00':
                    time = 'বিকাল ০৬:৩০'
                    break;
                case '19:00:00':
                    time = 'সন্ধ্যা ০৭:০০'
                    break;
                case '19:30:00':
                    time = 'সন্ধ্যা ০৭:৩০'
                    break;
                case '20:00:00':
                    time = 'রাত ০৮:০০'
                    break;
                case '20:30:00':
                    time = 'রাত ০৮:৩০'
                    break;
                case '21:00:00':
                    time = 'রাত ০৯:০০'
                    break;
                case '21:30:00':
                    time = 'রাত ০৯:৩০'
                    break;
                case '22:00:00':
                    time = 'রাত ১০:০০'
                    break;
                case '22:30:00':
                    time = 'রাত ১০:৩০'
                    break;
                case '23:00:00':
                    time = 'রাত ১১:০০'
                    break;
                case '23:30:00':
                    time = 'রাত ১১:৩০'
                    break;
                default:
                    break;
            }

            return time;
        },

        convertMonthName(str) {
            let month_no = str.split('-')[1]
            let month;
            switch (month_no) {
                case '01':
                    month = 'জানুয়ারী'
                    break;
                case '02':
                    month = "ফেব্রুয়ারী"
                    break;
                case '03':
                    month = "মার্চ"
                    break;
                case '04':
                    month = "এপ্রিল"
                    break;
                case '05':
                    month = "মে"
                    break;
                case '06':
                    month = "জুন"
                    break;
                case '07':
                    month = "জুলাই"
                    break;
                case '08':
                    month = 'আগস্ট'
                    break;
                case '09':
                    month = 'সেপ্টেম্বর'
                    break;
                case '10':
                    month = 'অক্টোবর'
                    break;
                case '11':
                    month = 'নভেম্বর'
                    break;
                case '12':
                    month = 'ডিসেম্বর'
                    break;
                default:
                    break;
            }

            return month;
        },

        getDayName(str) {
            const date = new Date(str);
            const dayNames = ["রবি", "সোম", "মঙ্গল", "বুধ", "বৃহস্পতি", "শুক্র", "শনি"];
            const dayOfWeek = date.getDay();
            const dayName = dayNames[dayOfWeek];
            return dayName;
        },

        getFullDayName(str) {
            const date = new Date(str);
            const dayNames = ["রবিবার", "সোমবার", "মঙ্গলবার", "বুধবার", "বৃহস্পতিবার", "শুক্রবার", "শনিবার"];
            const dayOfWeek = date.getDay();
            const dayName = dayNames[dayOfWeek];
            return dayName;
        }
    }
}


export default engToBd;