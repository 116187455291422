<template>
	<div class="max-width mx-auto bg-gray-100 py-2 my-24">
        <div class="flex flex-col my-auto md:flex-row">
            <div class="w-1/2 w-full mx-auto my-auto p-5 ">
                <p class="uppercase text-primary mb-3 text-xl font-semibold">Be a car provider</p>
                <p class="text-base text-gray-600 mb-3">Boost up your business with us.</p>
                <p class="text-base text-gray-600 mb-3">We provide you fully automated</p>
                <p class="text-base text-gray-600 mb-3">online system.</p>
                <router-link :to="{name: 'Signup'}">
                    <button
                        class="px-5 text-gray-200 text-xs bg-primaryLight py-1 shadow-xs capitalize"
                    >Register Now
                    </button>
                </router-link>
            </div>
            <div class="w-1/2 p-5 mx-auto my-auto w-full md:ml-1">
                <img class="w-full pt-4" src="/images/provider-sec.png" alt="">
            </div>
        </div>
	</div>
</template>

<script>
	export default {
		nam: 'Registration'
	}
</script>
<style>
.max-width {
    max-width: 1260px;
}
</style>