<template>
	<div class="shadow-md p-10 m-1.5 bg-white rounded-lg min-h-96">
		<div class="h-40">
			<p>"{{ testimonial.statement }}"</p>
		</div>
		<div class="flex items-center">
			<RoundImage
		      :image="testimonial.image"
		      heightWeight="w-10 h-10"
		    />
		
			<div>
		        <p class="ml-3 text-sm sm:text-md font-semibold text-gray-700">{{ testimonial.name }}</p>
		        <p class="ml-3 text-xs text-gray-600">{{ testimonial.position }}</p>
		    </div>
		</div>
	</div>

</template>

<script>
    import RoundImage from '@/components/atom/RoundImage'
	export default {
		name: 'Testimonial',
	    components:{
	    	RoundImage
	    },
	    props: {
	    	testimonial: {
	    		required: true
	    	}
	    }
	}
</script>