<template>
	<div 
		x-description="Modal panel, show/hide based on modal state." 
		class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full" 
		role="dialog" 
		aria-modal="true" 
		aria-labelledby="modal-headline" 
		enter-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" 
		leave-class="opacity-100 translate-y-0 sm:scale-100">
			<div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
				<div class="sm:flex sm:items-start">
					<div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
						<img src="/images/icons/question.svg" class="w-10" alt="">
					</div>
					<div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
						<h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline"> {{ title }}</h3>
						<div class="mt-2">
							<p class="text-sm text-gray-500"> {{ text }} </p>
						</div>
					</div>
				</div>
			</div>
			<div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
				<button @click="$emit('denied')" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"> Cancel </button>
				<button @click="$emit('accepted', status)" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-green-300 shadow-sm px-4 py-2 bg-green-50 text-base font-medium text-gray-700 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"> Proceed </button>
			</div>
		</div>
</template>

<script>
	export default {
		name: 'ConfirmationDialog',
		emits: ['accepted', 'denied'],
		props: {
			title: {
				default: 'Confirmation'
			}, 
			text: {
				default: 'Are you sure ?'
			},
			status: {
				required: true
			}
		}
	}
</script>