<template>
	<div>
		<div class="mt-10 shadow-card min-h-[180px] bg-white rounded-t-md relative">
			<div class="flex justify-between items-center rounded-t-md bg-whiteBlue">
				<div class="flex justify-between items-center">
					<div
						class="bg-coolBlue max-w-xs sm:max-w-sm rounded-tl-md pl-4 py-2 text-xs relative text-white uppercase font-sans border-r-14 border-coolBlue"
					>
                        <span class="mdi mdi-calendar mr-1"></span>
						{{ trip.journey_date }}
                        <span class="mdi mdi-clock mr-1"></span>
						{{ trip.journey_time }}
					</div>
					<div class="hidden md:flex items-center pl-8">
                    <!--<div class="mr-3">
							<span class="mdi mdi-calendar-blank text-coolBlue mr-1"></span>
							<span class="text-bluishBlack font-medium uppercase text-sm">{{
								trip.journey_date
							}}</span>
						</div>
						<div>
							<span class="mdi mdi-clock-time-five text-coolBlue mr-1"></span>
							<span class="text-bluishBlack font-medium uppercase text-sm">{{
								trip.journey_time
							}}</span>
						</div>-->
					</div>
				</div>
				<div class="pr-2 flex justify-between space-x-2">
					<div class="notification inline-block px-2 sm:px-4 md:px-6">
						<slot name="notification"></slot>
					</div>
                    <!--<p v-if="trip.status == 'completed' &&  trip?.own_rating != null" class="bg-blue-400 text-gray-100 text-center text-xs px-3 py-1 rounded-sm">
                        <span class="mdi mdi-star text-yellow-200"></span>
                        {{ trip?.own_rating != null ? trip?.own_rating?.rating :  "Not Rated"  }}
                    </p>-->
					<Status :status="trip.status" />
					<p
						class="text-pri-sm sm:text-pri-xs md:text-pri-md inline-block"
						v-if="trip?.price"
					>
						<span class="px-2 text-pri-md sm:text-pri-xs"
							>৳{{ parseInt(trip.price) }} 
						</span
						>
					</p>
				</div>
			</div>

			<div class="flex justify-between md:hidden px-2 mt-4">
            <!--<div class="mr-3">
					<span class="mdi mdi-calendar-blank text-coolBlue mr-1"></span>
					<span class="text-bluishBlack font-medium uppercase text-sm">{{
						trip.journey_date
					}}</span>
				</div>
				<div>
					<span class="mdi mdi-clock-time-five text-coolBlue mr-1"></span>
					<span class="text-bluishBlack font-medium uppercase text-sm">{{
						trip.journey_time
					}}</span>
				</div>-->
			</div>
			<div
				class="hidden md:grid grid-cols-12 sm:grid-cols-9 lg:grid-cols-9 gap-3 md:pl-8 pl-2 pt-6 pb-2"
			>
				<div class="col-span-12 sm:col-span-3 lg:col-span-2">
					<div class="font-semibold text-gray-400 text-sm">
						{{ $t("triCreateForm.pickupLocation") }}
					</div>
					<div
						class="text-bluishBlack text-sm tracking-widest font-medium my-2 break-words"
						v-if="trip && trip.geo_location"
					>
                        {{ trip?.pickup_full_address }},
						{{
							$store.state.language == "en"
								? trip.geo_location.location.pick_district.coordinate.name.en
								: trip.geo_location.location.pick_district.coordinate.name.bn
						}}
					</div>
				</div>
				<div class="col-span-12 sm:col-span-3 lg:col-span-2">
					<div class="font-semibold text-gray-400 text-sm">
						{{ $t("triCreateForm.destinationLocation") }}
					</div>
					<div
						class="text-bluishBlack text-sm tracking-widest font-medium my-2 break-words"
						v-if="trip && trip.geo_location"
					>
                        {{ trip?.destination_full_address }},
						{{
							$store.state.language == "en"
								? trip.geo_location.location.dest_district.coordinate.name.en
								: trip.geo_location.location.dest_district.coordinate.name.bn
						}}

					</div>
				</div>
				<!-- <div
					v-if="trip.status == 'hold'"
					class="col-span-6 sm:col-span-3 lg:col-span-1"
				>
					<div class="font-semibold text-gray-400 text-sm">Car. Reg NO</div>
					<div
						class="text-bluishBlack text-sm tracking-widest font-medium my-2"
						v-if="trip"
					>
						{{ trip.car?.registration_number }}
					</div>
				</div> -->
				<div class="col-span-6 sm:col-span-3 lg:col-span-3">
					<div class="font-semibold text-gray-400 text-sm">Car Info</div>
					<div
						class="text-bluishBlack text-sm tracking-widest font-medium my-2 grid"
						v-if="trip && trip.car"
					>
						<span class="car-details">
							{{ trip.car?.car_brand }} {{ trip.car?.model || "N/A" }}
							{{ trip.car.manufacture_year || "" }} ({{ trip.no_of_seat }}
							seats)
						</span>
					</div>
				</div>

				<div v-if="trip?.status != 'active'">
					<p class="capitalize text-xs text-gray-500">
						{{ trip.created_by }} Info
					</p>
					<div class="w-full grip">
						<div class="w-full">
							<div class="grid">
								<div
									class="text-bluishBlack text-sm tracking-widest font-medium my-2"
								>
									{{ booking?.trip?.user?.name }}
								</div>

								<div
									class="text-blue-500 text-sm tracking-widest font-medium my-2"
									v-if="
										booking?.status == 'confirmed' &&
										trip?.status != 'completed'
									"
								>
									<span>
										<a
											class="flex flex-wrap gap-1 items-center"
											v-if="
												booking &&
												booking.trip &&
												booking.trip.user &&
												booking.trip.user.phone
											"
											:href="getTel(booking.trip.user.phone)"
										>
											<span class="mdi mdi-phone sm:hidden block"></span>
											{{ getModifiedPhoneNumber(booking.trip.user.phone) }}</a
										></span
									>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="absolute right-1 bottom-1">
                    <!--<button
						v-if="showBookNow && booking.status !== 'requested'"
						@click="handleTripRequest()"
						class="bg-coolBlue text-white text-sm md:text-xs w-fit px-4 py-2 rounded focus:shadow-md focus:outline-none hover:opacity-90 transition duration-500 shadow-btn"
					>
						{{ $t("triCreateForm.create") }}
					</button>-->

                    <button
                        v-if="showBookNow && booking.status !== 'requested'"
                        @click="handlePassengerBookingModal(trip?.id)"
                        class="h-8 mt-4 block bg-coolBlue text-white text-xs px-4 py-2 rounded w-fit focus:shadow-md focus:outline-none hover:opacity-90 transition duration-500 shadow-btn"
                    >
                        {{ $t("triCreateForm.bidNow") }}
                    </button>
				</div>
			</div>

			<!-- mobile view -->
			<div class="md:hidden">
				<div class="px-3 mt-4">
					<DestinationViewer :trip="trip" />
				</div>
				<div class="px-3 mt-4">
					<CarViewer :trip="trip" cardBg="bg-blue-50 items-center p-2" />
				</div>

				<div v-if="showCreatorInfo" class="px-2 mt-3">
					<UserInformation
						title="Provider Information"
						cardBg="bg-blue-50"
						:trip="booking"
						:image="booking?.trip?.user?.profile_photo_url"
						:name="booking?.trip?.user?.name"
						:phone="booking.status == 'confirmed' ? trip?.user?.phone : '--'"
					/>
				</div>
				<div v-if="showBookNow && booking.status !== 'requested'" class="md:hidden flex justify-end px-3 h-10 mb-5">
					<button
                        @click="handlePassengerBookingModal(trip?.id)"
						class="h-8 mt-4 block bg-coolBlue text-white text-xs px-4 py-2 rounded w-fit focus:shadow-md focus:outline-none hover:opacity-90 transition duration-500 shadow-btn"
					>
                        {{ $t("triCreateForm.bidNow") }}
					</button>
				</div>
			</div>
			<!-- end mobile view -->
		</div>
		<div class="border-b-3 border-coolBlue"></div>
	</div>
</template>

<script>
import TripStatus from "@/components/molecule/card/TripStatusComponent";
import DestinationViewer from "@/components/atom/DestinationViewer";
import UserInformation from "@/components/atom/UserInformation";
import SeatAndTripTypeViewer from "@/components/atom/SeatAndTripTypeViewer";
import { mapMutations , mapGetters } from "vuex";
import CarViewer from "@/components/atom/CarViewer";
import userMixin from "@/mixins/userMixin";
import Form from "@/services/form";
import Status from "@/components/atom/Status";
export default {
	name: "FeedProviderCard",
	mixins: [userMixin],
	//extends: providersCardFunctions,
	components: {
		TripStatus,
		CarViewer,
		DestinationViewer,
		UserInformation,
		SeatAndTripTypeViewer,
		Status,
	},
	data() {
		return {
			form: new Form({
				action: "",
			}),
		};
	},

	computed: {
		...mapGetters({
			user: "user/getUser",
		}),
		bookingType() {
			return this.userType == "customer" ? "bookingProviders" : "bookingUsers";
		},
        
		confirmBookingStatus() {
			return this.trip.status == "hold" && this.bookingStatus == "confirmed"
				? true
				: false;
		},

		carInfoFromTrip() {
			let brand = this.trip.car_brand ?? "";
			let model = this.trip.car_model ?? "";
			let manufactureYear = this.trip.manufacture_year ?? "";
			return brand + " " + model + " " + manufactureYear;
		},
	},

	props: {
		trip: {
			type: Object,
			default: "",
		},
		booking: {
			type: Object,
			default: {},
		},
		showCreatorInfo: {
			type: Boolean,
			default: false,
		},
		showBookNow: {
			type: Boolean,
			default: true,
		},
		bookingStatus: {
			default: null,
		},
		showRightmostImage: {
			type: Boolean,
			default: true,
		},
	},
	
	methods: {
		...mapMutations({
			setTripId: "setTripId",
			setDialogComponent: "setDialogComponent",
			updateTripStatusinBooking: "bookings/updateTripStatusinBooking",
			setSidebarStatus: "setSidebarStatus",
		}),
        handleDialog(name) {
			this.setSidebarStatus(false);
			this.setDialogComponent(name);
		},
		handleTripRequest(){
			if(this.user){
			   this.setDialogComponent('TripCreateForm')
			}
			else {
				this.$router.push({name: 'Signin'})
			}
		},
		updateTripStatus(status) {
			this.form
				.post(`/trip/status/${this.trip.id}`, {
					status: status,
					_method: "put",
				})
				.then((response) => {
					if (response.success) {
						this.updateTripStatusinBooking({
							id: this.trip.id,
							status: status,
						});
					}
					this.setToaster(response.message);
				});
		},

		getCarImage() {
			if (this.trip.car?.images?.length) {
				return this.trip.car.images[0].file_path;
			}
			if (this.trip.car?.image?.length) {
				return this.trip.car.image[0].file_path;
			}
			return "/images/sample.png";
		},

		handlePassengerBookingModal(tripId) {
			this.$router.push({ name: "TripBooking", params: { slug: tripId } });
		},

		getModifiedPhoneNumber(number) {
			return number.slice(3);
		},

		getTel(number) {
			return "tel:" + number;
		},
	},

	mounted() {},
};
</script>
<style scoped>
@import "./providers-card.styles.css";

.shadow-card {
	min-height: 180px;
}
</style>
