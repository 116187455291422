<template>
	<div class="bg-gray-100 pb-5">
		<div class="container mx-auto md:p-4 max-w-7xl">
			<h1 class="text-center text-4xl font-semibold primary-color" v-if="$route.name !=='Feed'">
				Available Trips
			</h1>

			<div v-if="isEmpty($route.query)">
				<div v-if="feeds.length < 1 && !tripManager.skeletonLoader">
					<NoData />
				</div>
				<template v-else>
					<Card
						v-for="(row, index) in feeds"
						:trip="row"
						:key="index"
						class="mt-10"
						:currentCard="currentCard"
					/>
				</template>
			</div>

			<div v-else>
				<div v-if="trips && trips.length < 1 && !tripManager.skeletonLoader">
					<NoData />
				</div>
				<template v-else>
					<Card
						v-for="(row, index) in trips"
						:trip="row"
						:key="index"
						class="mt-10"
						:currentCard="currentCard"
					/>
				</template>
			</div>
			<button
				v-if="isFeeds && showLoadMoreBtn"
				@click="gotoFeed"
				class="
					rounded
					px-2
					py-1
					text-sm text-blue-400
					border-2
					mt-10
					border-blue-400
					hover:bg-blue-100
					transition
					duration-400
					block
					mx-auto
				"
			>
				Load more
			</button>
			<!-- </div> -->
			<Loader v-if="tripManager.skeletonLoader" />
		</div>
		<Observer v-if="onScrollPaginate" @intersect="fetchNextPage" />
	</div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import TitleSubtitle from "@/components/atom/TitleSubtitle";
import userMixin from "@/mixins/userMixin";
import Card from "@/components/molecule/card/Card";
import Loader from "@/components/molecule/loader/BookingLoader";
import TripManager from "@/services/module/trip";
import NoData from "@/components/atom/NoData";
import Observer from "@/components/atom/Observer";

export default {
	name: "FeedSection",
	mixins: [userMixin],
	components: {
		Card,
		TitleSubtitle,
		Loader,
		NoData,
		Observer,
	},

	data() {
		return {
			tripManager: new TripManager(),
			pageMeta: {},
			currentPage: 1,
		};
	},

	computed: {
		...mapGetters({
			feeds: "feeds/getFeeds",
			getTripSearchQuery: "getTripSearchQuery",
		}),

		isFeeds() {
			return this.feeds.length > 0;
		},

		createdBy() {
			if (!this.userType) {
				return "PROVIDER";
			}
			return this.userType == "customer" ? "PROVIDER" : "CUSTOMER";
		},

		currentCard() {
			return this.userType == "provider" ? "feedCustomer" : "feedProvider";
		},

		totalPage() {
			if (this.pageMeta.per_page > this.pageMeta.total) {
				return 1;
			}
			let pages = this.pageMeta.total / this.pageMeta.per_page;
			let pageFraction = this.pageMeta.total % this.pageMeta.per_page;
			return pageFraction == 0 ? pages : Math.ceil(pages);
		},
	},

	methods: {
		...mapMutations({
			setFeeds: "feeds/setFeeds",
			emptyExistingFeeds: "feeds/emptyExistingFeeds",
		}),
		gotoFeed() {
			this.$router.push({ name: "Feed" });
		},

		async fetchFeedList() {
			let data = {
				createdBy: this.createdBy,
				descOrderColumnName: "created_at",
				page: this.currentPage,
			};

			let searchQuery = { ...data, ...this.getTripSearchQuery };

			let res = await this.tripManager.searchTrip(searchQuery);
			if (res.success) {
				if(this.$route.name == 'Home'){
					this.setFeeds(res.data.data.slice(0,5))
				}
				else {
					this.setFeeds(res.data.data);
				}
				
				this.pageMeta = res.data.meta;
			}
		},

		fetchNextPage() {
			if (this.tripManager.loading == true) return;
			if (this.totalPage <= this.currentPage) return;
			this.currentPage++;
			this.fetchFeedList();
		},

		isEmpty(obj) {
			return Object.keys(obj).length === 0;
		},
	},

	props: {
		onScrollPaginate: {
			default: true,
		},
		showLoadMoreBtn: {
			default: false,
		},
		trips: {
			type: Object,
		},
	},
	mounted() {
	},
	created() {
		this.emptyExistingFeeds();
		this.fetchFeedList();
	},
};
</script>

<style>
.primary-color {
	color: #ff4365;
}
</style>
