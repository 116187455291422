import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
	{
		path: "/",
		name: "Home",
		component: Home,
		meta: {
			layout: "master",
			title: "Rent A Car in Bangladesh || অল্প টাকায় বেশি ভ্রমণ!",
		},
	},
	{
		path: "/about",
		name: "About",
		component: () => import("../views/About.vue"),
		meta: { title: "About - Return Trip" },
	},
	{
		path: "/contact",
		name: "Contact",
		component: () => import("../views/ContactUs.vue"),
		meta: { title: "Contact - Return Trip" },
	},

	{
		path: "/trip-request",
		name: "TripCreate",
		component: () => import("../views/trips/CreateTrip.vue"),
		meta: {
			requiresAuth: true,
			meta: "Trip Request - Return Trip",
		},
	},
	{
		path: "/notifications/:page?",
		name: "Notifications",
		component: () => import("../views/Notification.vue"),
		meta: { title: "Notifications - Return Trip" },
	},
	{
		path: "/payment-history",
		name: "PaymentHistory",
		component: () => import("../views/PaymentHistory.vue"),
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/trips",
		component: () => import("../views/Trip.vue"),
		name: "trips",
		children: [
			{
				path: "/trips",
				name: "RequestTrips",
				component: () =>
					import("../components/molecule/trip/MyRequestTrips.vue"),
				meta: {
					requiresAuth: true,
					title: "My trips - Return Trip",
				},
			},
			{
				path: "/previous-trips",
				name: "PreviousTrips",
				component: () =>
					import("../components/molecule/trip/MyPreviousTrips.vue"),
				meta: {
					requiresAuth: true,
					title: "Previous trips - Return Trip",
				},
			},
			{
				path: "/booking-trips",
				name: "BookingTrips",
				component: () =>
					import("../components/molecule/trip/MyBookingTrips.vue"),
				meta: {
					requiresAuth: true,
					title: "Booked trips - Return Trip",
				},
			},
		],
	},
	{
		path: "/help",
		name: "Help",
		component: () => import("../views/Help/Help.vue"),
		meta: {
			requiresAuth: true,
			title: "Help - Return Trip",
		},
	},
	{
		path: "/video-tutorial",
		name: "VideoTutorial",
		component: () => import("../views/Help/VideoList.vue"),
		meta: {
			requiresAuth: true,
			title: "Help - Return Trip",
		},
	},

	{
		path: "/bkash-callback",
		name: "BkashCallback",
		component: () => import("../views/BkashCallback.vue"),
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/referral",
		name: "Referral",
		component: () => import("../views/Referral.vue"),
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/feed",
		name: "Feed",
		component: () => import("../views/Feed.vue"),
		meta: {
			requiresAuth: true,
			title: "Feed - Return Trip",
		},
	},
	{
		path: "/provider-booking-feed",
		name: "ProviderBookingFeed",
		component: () => import("../components/molecule/feed/ProviderBookingFeed.vue"),
		meta: {
			requiresAuth: true,
			title: "Feed - Return Trip",
		},
	},
	{
		path: "/customer/profile",
		name: "customerProfile",
		component: () => import("../views/profile/Customer.vue"),
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/provider/profile",
		name: "ProviderProfile",
		component: () => import("../views/profile/Provider.vue"),
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/provider/profile#my-drivers",
		name: "ProviderDriver",
		component: () => import("../views/profile/Provider.vue"),
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/provider/profile#my-cars",
		name: "ProviderCar",
		component: () => import("../views/profile/Provider.vue"),
		meta: {
			requiresAuth: true,
		},
	},

	{
		path: "/provider/car/index",
		name: "CarIndex",
		component: () => import("../views/provider/CarIndex.vue"),
		meta: {
			requiresAuth: true,
		},
	},

	{
		path: "/provider/car/store",
		name: "CarStore",
		component: () => import("../views/provider/CarStore.vue"),
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/provider/car/edit/:id",
		name: "CarEdit",
		component: () => import("../views/provider/CarEdit.vue"),
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/driver/profile",
		name: "DriverProfile",
		component: () => import("../views/profile/Driver.vue"),
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/driver/add",
		name: "DriverAdd",
		component: () => import("../views/AddDriver"),
		beforeEnter: (to, from, next) => {
			const userData = JSON.parse(localStorage.getItem("trip_auth_user"));
			if (userData.type === "provider") {
				next();
			} else {
				next({
					name: "Home",
				});
			}
		},
		meta: {
			requiresAuth: true,
			meta: "Trip - Return Trip",
		},
	},

	{
		path: "/trip-bidding/:slug",
		name: "TripBidding",
		component: () => import("../views/TripBidding.vue"),
		meta: {
			requiresAuth: true,
			meta: "Trip - Return Trip",
		},
	},


	{
		path: "/trip-details/:slug",
		name: "TripDetails",
		component: () => import("../views/TripDetails.vue"),
		meta: {
			requiresAuth: true,
			meta: "Trip - Return Trip",
		},
	},

	{
		path: "/trip-booking/:slug",
		name: "TripBooking",
		component: () => import("../views/TripBooking.vue"),
		meta: {
			requiresAuth: true,
			meta: "Trip Booking - Return Trip",
		},
	},
	{
		path: "/estimated-fare",
		name: "EstimatedFare",
		component: () => import("../views/EstimatedFare.vue"),
		meta: {
			requiresAuth: true,
			meta: "Trip Booking - Return Trip",
		}
	},
	{
		path: "/:catchAll(.*)",
		name: "404",
		component: () => import("../views/404.vue"),
	},
	{
		path: "/before-sign-in",
		name: "BeforeSignin",
		component: () => import("../views/auth/BeforeSignin.vue"),
		meta: {
			layout: "auth",
			title: "Return Trip",
			metaTags: [
				{
					name: 'description',
					content: 'Now is the time to sign in to your account and enjoy the best car rental services'
				},
				{
					property: 'og:description',
					content: 'Now is the time to sign in to your account and enjoy the best car rental services'
				}
			]
		},
	},
	{
		path: "/signin",
		name: "Signin",
		component: () => import("../views/auth/Signin.vue"),
		meta: {
			layout: "auth",
			title: "Sign in - Return Trip",
			metaTags: [
				{
					name: 'description',
					content: 'Now is the time to sign in to your account and enjoy the best car rental services'
				},
				{
					property: 'og:description',
					content: 'Now is the time to sign in to your account and enjoy the best car rental services'
				}
			]
		},
	},
	{
		path: "/signup",
		name: "Signup",
		component: () => import("../views/auth/Signup.vue"),
		meta: {
			layout: "auth",
			title: "Sign up - Return Trip",
			metaTags: [
				{
					name: 'description',
					content: 'Sign up now and get access to the best car rental deals with us!'
				},
				{
					property: 'og:description',
					content: 'Sign up now and get access to the best car rental deals with us!'
				}
			]
		},
	},
	{
		path: "/forgot-password",
		name: "ForgotPassword",
		component: () => import("../views/auth/SendEmail.vue"),
		meta: { layout: "auth", title: "Forgot password - Return Trip" },
	},
	{
		path: "/reset-password",
		name: "ResetPassword",
		component: () => import("../views/auth/ResetPassword.vue"),
		meta: { layout: "auth", title: "Reset password - Return Trip" },
	},
	{
		path: "/impersonate",
		name: "Impersonate",
		component: () => import("../views/Impersonate.vue"),
		meta: { layout: "impersonate" },
	},
	{
		path: "/terms-conditions",
		name: "TermsCondition",
		component: () => import("../views/TermsAndCondition.vue"),
		meta: { title: "Terms and Conditions - Return Trip" },
	},
	{
		path: "/privacy-policy",
		name: "PrivacyPolicy",
		component: () => import("../views/PrivacyPolicy.vue"),
		meta: { title: "Privacy policy - Return Trip" },
	},
];

const router = createRouter({
	history: createWebHistory(),

	routes,

	scrollBehavior(to, from, savedPosition) {
		// return savedPosition || new Promise((resolve) => {
		//   setTimeout(() => resolve({top: 0, behavior: 'smooth'}),  500)
		// })

		if (savedPosition) {
			return savedPosition;
		} else if (to.hash) {
			return {
				el: to.hash,
				behavior: "smooth",
			};
		} else {
			return new Promise((resolve) => {
				setTimeout(() => resolve({ top: 0, behavior: "smooth" }), 500);
			});
		}
	},
});

const userType = function getUser() {
	const userData = JSON.parse(localStorage.getItem("trip_auth_user"));
};

router.beforeEach((to, from, next) => {
	window.document.title =
		to.meta && to.meta.title ? to.meta.title : "Return Trip";
	if (to.matched.some((record) => record.meta.requiresAuth)) {
		let token =
			localStorage.getItem("token") || localStorage.getItem("imp_token");
		if (token) {
			next();
			return;
		}
		next("/signin");
	} else {
		next();
	}
});

export default router;
