<template>
    <div class="custom-date">
        <p v-if="date" class="text-center text-2xl font-bold mb-5">{{ convertMonthName(date) }}</p>
<!--        <div class="cursor-pointer flex align-middle justify-center gap-1">
            <div class="w-14 h-14" v-for="(date , index) in selectableDates" :key="index" @click="clickDate(date)">
                <div class="date-month rounded px-2 py-1 max-w-md"
                     :class="selectedDate === date ? 'bg-red-600' : 'bg-gray-400'">
                    <div class="day flex-col align-middle justify-center">
                        <p class="text-center text-xl font-bold">{{ displayDate(date).dayInNumber }}</p>
                        <p class="text-center text-xs">{{ displayDate(date).day }}</p>
                    </div>
                </div>
            </div>
        </div>-->
        <div class="flex justify-center items-center">
            <div class="grid grid-cols-5 gap-2">
                <div
                    class="w-12 h-12 border border-gray-300 flex flex-col justify-center items-center rounded-lg shadow-md"
                    v-for="(date, index) in selectableDates"
                    :key="index"
                    @click="clickDate(date)"
                    :class="{ 'bg-red-600 text-white': selectedDate === date, 'bg-white text-black': selectedDate !== date }"
                >
              <span class="text-sm font-bold">
                {{ displayDate(date).dayInNumber }}
              </span>
                    <span class="text-xs">
                {{ displayDate(date).day }}
              </span>
                </div>
            </div>
        </div>


        <!--<swiper
        :class="[deviceType === 'mobile' ? 'mobile': 'other']"
        @swiper="onSwiper"
        @slideChange="onSlideChange"
        :style="{maxWidth: maxWidth}"
        :navigation="true"
        :modules="modules" 
        :effect="'coverflow'"
        :grabCursor="true"
        :centeredSlides="true"
        :slidesPerView="2"
        :coverflowEffect="{
        rotate: 0,
        stretch: 80,
        depth: 160,
        modifier: 1,
        slideShadows: false
        }">
            <swiper-slide v-for="(date , index) in selectableDates" :key="index" :id="date" @change="getDateName(date)">
                <div class="date-month">
                    <div class="day grid gap-1">
                        <p class="day-in-number text-3xl font-bold">{{ displayDate(date).dayInNumber }}</p>
                        <p class="day-name">{{ displayDate(date).day }}</p>
                    </div>
                </div>
            </swiper-slide>
        </swiper>-->
    </div>
</template>

<script>
import {Swiper, SwiperSlide} from "swiper/vue";
import "swiper/css";
import "swiper/css/navigation";
import 'swiper/css/effect-coverflow';
import {defineComponent} from "vue";
import {Navigation, EffectCoverflow} from "swiper/modules";
import engToBd from "@/mixins/engToBd"

export default defineComponent({
    name: 'CustomDate',
    mixins: [engToBd],
    components: {
        Swiper,
        SwiperSlide,
    },
    setup(props, {emit}) {
        const onSwiper = async (swiper) => {
            const activeSlide = await swiper.slides[swiper.activeIndex];
            const id = activeSlide.id;
            emit('change-date', id)
        };
        const onSlideChange = async (swiper) => {
            const activeSlide = await swiper.slides[swiper.activeIndex];
            const id = activeSlide.id;
            emit('change-date', id)
        };
        return {
            onSwiper,
            onSlideChange,
            modules: [Navigation, EffectCoverflow],
        };

    },
    data() {
        return {
            selectedDate: null,
            minDate: null,
            maxDate: null,
            monthName: '',
            windowWidth: window.innerWidth
        };
    },
    props: {
        journeyDate: {
            type: String
        },
        returnDate: {
            type: String
        },
        changeDate: {
            type: String
        },
        deviceType: {
            type: String
        },
        date: {
            type: String
        }
    },
    computed: {
        selectableDates() {
            const dates = [];
            let currentDate = new Date(this.minDate);

            while (currentDate <= new Date(this.maxDate)) {
                dates.push(this.formatDate(currentDate));
                currentDate = this.getNextDate(currentDate);
            }
            return dates;
        },
        maxWidth() {
            if (this.windowWidth < 430) {
                let width = this.windowWidth - 30;
                return width + 'px';
            }
            return '430px';
        }
    },
    methods: {
        formatDate(date) {
            const formattedDate = new Date(date);
            const year = formattedDate.getFullYear();
            const month = String(formattedDate.getMonth() + 1).padStart(2, '0');
            const day = String(formattedDate.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        },

        displayDate(date) {
            let month = this.convertMonthName(date);
            let day = this.getDayName(date)
            let dayInNumber = this.engToBdNum(date.split('-')[2])
            return {month, day, dayInNumber};
        },
        getNextDate(date) {
            const nextDate = new Date(date);
            nextDate.setDate(nextDate.getDate() + 1);
            return nextDate;
        },

        clickDate(date) {
            this.selectedDate = date;
            this.$emit('change-date', date);
        },
    },
    mounted() {
        const today = new Date();
        //console.log(today);
        //set today november 29, 2023
        //today.setFullYear(2023, 10, 29);
        //console.log(today);
        this.minDate = today.toISOString().split('T')[0];
        const nextDays = new Date(today);
        nextDays.setDate(today.getDate() + 4);
        this.maxDate = nextDays.toISOString().split('T')[0];
        this.selectedDate = this.minDate;
        if (this.journeyDate && this.journeyDate != undefined) {
            this.selectedDate = this.journeyDate
        }
        if (this.returnDate && this.returnDate != undefined) {
            this.selectedDate = this.returnDate
        }

        window.onresize = () => {
            this.windowWidth = window.innerWidth
        }
    }
});
</script>

<style lang="scss">
.custom-date {
    font-family: 'Noto Sans Bengali', sans-serif;
}

.swiper {
    width: 100%;
    max-width: 430px;
    height: 100%;

    &.mobile {
        .swiper-button-prev::after, .swiper-button-next::after {
            display: none !important;
        }
    }
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;

    .date-month {
        padding: 10px 5px;
        width: fit-content;
        min-width: 80px;
        background: rgba(187, 198, 208, 0.30);
        border-radius: 10px;
        box-shadow: 0px 2px 6px 0px rgba(119, 129, 138, 0.80);

        .month {
            color: #000;
            font-size: 18px;
            font-weight: 900;
        }

        .day {
            height: fit-content;
            padding: 10px 5px;
            width: 100%;
            color: #000;
            font-weight: 700;
            place-content: center;
            border-radius: 10px;
            font-size: 14px;
            line-height: 1.2;
        }
    }
}

.swiper-slide-active {
    .date-month {
        padding: 10px 5px;
        width: fit-content;
        min-width: 80px;
        background: #D71920;
        border-radius: 10px;
        box-shadow: 0px 2px 6px 0px rgba(119, 129, 138, 0.80);

        .month {
            color: #ffffff;
            font-size: 24px;
            font-weight: 900;
        }

        .day {
            height: fit-content;
            padding: 10px 5px;
            width: 100%;
            color: #fff;
            font-weight: 700;
            place-content: center;
            border-radius: 10px;
        }
    }
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.swiper-button-prev, .swiper-button-next {
    color: #D72028;

    &::after {
        font-size: 24px;
        font-weight: 900;
        position: absolute;
        top: 15px;
    }
}

.swiper-button-prev {
    &::after {
        left: 10px;
    }
}

.swiper-button-next {
    &::after {
        left: calc(100% - 10px);
    }
}
</style>