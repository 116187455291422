<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <transition name="fade">
    <div class="fixed z-10 inset-0 overflow-y-auto" v-if="show" style="background: rgba(0,0,0,.5)">
        <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
        >
        <div
            @click="$emit('closeDialog')"
            class="fixed inset-0 transition-opacity"
            aria-hidden="true"
        >
            <div class="absolute inset-0"></div>
        </div>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true" >&#8203;</span>
        <div 
        class="inline-block align-bottom overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle"
        >
            <div class="">
            <slot></slot>
            </div>
        </div>
        </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "Dialog",
  props: {
    show: {
        default: false
    }
  }
};
</script>