<template>
  <div class="container max-width mx-auto mt-10">
    <TitleSubtitle
      title="simple & fast"
      subtitle="how it work"
    />
    <div class="flex flex-col md:flex-row justify-between mt-10">
      <template v-for="(instruction, i) in instructions" :key="i">
        <Instruction  :instruction="instruction" :serial="i" />
      </template>
    </div>
  </div>
</template>

<script>
  import TitleSubtitle from '@/components/atom/TitleSubtitle'
  import Instruction from '@/components/molecule/home/Instruction'
  import {instructions} from '@/data/instruction'
  export default {
    name: 'HowItWork',
    components:{
      TitleSubtitle,
      Instruction
    },
    props:{
    },
    data(){
      return{
        instructions
      }
    },
    computed:{

    },
    watch:{

    },
    methods:{

    }
  }
</script>

<style>
    .max-width {
        max-width: 1260px;
    }
</style>